/**
 * @generated SignedSource<<50af9a44876ff79d9ecaa32871f14ccb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "backgroundTaskId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "backgroundTaskId",
    "variableName": "backgroundTaskId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "BackgroundTask",
  "kind": "LinkedField",
  "name": "backgroundTask",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ok",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Error",
  "kind": "LinkedField",
  "name": "error",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Thread",
  "kind": "LinkedField",
  "name": "threads",
  "plural": true,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "channelId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "channelName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hiddenCount",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "threadId",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "folderId",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "wMemberId",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "wChannelId",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "integrationIcon",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "integrationName",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "etag",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastChatAt",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastMessage",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastReadAt",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unreadCount",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "externalProfile",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "concreteType": "MemberProfile",
  "kind": "LinkedField",
  "name": "inboxProfile",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    (v13/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profilePicUrl",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "backgroundTaskId",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "concreteType": "Folder",
  "kind": "LinkedField",
  "name": "destinationFolder",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v13/*: any*/),
    (v12/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InboxQuery_backgroundTaskSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BackgroundTaskSubscription",
        "kind": "LinkedField",
        "name": "backgroundTask",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UpdateThreadsPayload",
            "kind": "LinkedField",
            "name": "updateThreads",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ThreadsConflictResult",
                "kind": "LinkedField",
                "name": "threadsConflictResult",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ThreadsConflict",
                    "kind": "LinkedField",
                    "name": "conflicts",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Thread",
                        "kind": "LinkedField",
                        "name": "threads",
                        "plural": true,
                        "selections": [
                          (v11/*: any*/),
                          (v12/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Folder",
                            "kind": "LinkedField",
                            "name": "folder",
                            "plural": false,
                            "selections": [
                              (v12/*: any*/),
                              (v13/*: any*/),
                              (v14/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v15/*: any*/),
                          (v16/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Channel",
                            "kind": "LinkedField",
                            "name": "wChannel",
                            "plural": false,
                            "selections": [
                              (v13/*: any*/),
                              (v17/*: any*/),
                              (v18/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v19/*: any*/),
                          (v20/*: any*/),
                          (v21/*: any*/),
                          (v22/*: any*/),
                          (v23/*: any*/),
                          (v24/*: any*/),
                          (v25/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Member",
                            "kind": "LinkedField",
                            "name": "member",
                            "plural": false,
                            "selections": [
                              (v26/*: any*/),
                              (v27/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v28/*: any*/),
              (v29/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InboxQuery_backgroundTaskSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BackgroundTaskSubscription",
        "kind": "LinkedField",
        "name": "backgroundTask",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UpdateThreadsPayload",
            "kind": "LinkedField",
            "name": "updateThreads",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ThreadsConflictResult",
                "kind": "LinkedField",
                "name": "threadsConflictResult",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ThreadsConflict",
                    "kind": "LinkedField",
                    "name": "conflicts",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Thread",
                        "kind": "LinkedField",
                        "name": "threads",
                        "plural": true,
                        "selections": [
                          (v11/*: any*/),
                          (v12/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Folder",
                            "kind": "LinkedField",
                            "name": "folder",
                            "plural": false,
                            "selections": [
                              (v12/*: any*/),
                              (v13/*: any*/),
                              (v14/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v15/*: any*/),
                          (v16/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Channel",
                            "kind": "LinkedField",
                            "name": "wChannel",
                            "plural": false,
                            "selections": [
                              (v13/*: any*/),
                              (v17/*: any*/),
                              (v18/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v19/*: any*/),
                          (v20/*: any*/),
                          (v21/*: any*/),
                          (v22/*: any*/),
                          (v23/*: any*/),
                          (v24/*: any*/),
                          (v25/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Member",
                            "kind": "LinkedField",
                            "name": "member",
                            "plural": false,
                            "selections": [
                              (v26/*: any*/),
                              (v27/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v28/*: any*/),
              (v29/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ed7442287c6feacc31d46e5e4446e77e",
    "id": null,
    "metadata": {},
    "name": "InboxQuery_backgroundTaskSubscription",
    "operationKind": "subscription",
    "text": "subscription InboxQuery_backgroundTaskSubscription(\n  $backgroundTaskId: ID!\n) {\n  backgroundTask(backgroundTaskId: $backgroundTaskId) {\n    backgroundTask {\n      id\n      status\n    }\n    updateThreads {\n      ok\n      error {\n        code\n        message\n      }\n      threads {\n        id\n      }\n      threadsConflictResult {\n        conflicts {\n          channelId\n          channelName\n          hiddenCount\n          totalCount\n          threads {\n            threadId\n            folderId\n            folder {\n              folderId\n              name\n              color\n              id\n            }\n            wMemberId\n            wChannelId\n            wChannel {\n              name\n              integrationIcon\n              integrationName\n              id\n            }\n            etag\n            title\n            updatedAt\n            lastChatAt\n            lastMessage\n            lastReadAt\n            unreadCount\n            member {\n              externalProfile\n              inboxProfile {\n                firstName\n                lastName\n                name\n                profilePicUrl\n              }\n              id\n            }\n            id\n          }\n        }\n      }\n      backgroundTaskId\n      destinationFolder {\n        id\n        name\n        folderId\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "7b628b48798fdb27323d3b8c4881f2f1";

module.exports = node;
