import styled from "@emotion/styled"
import { css } from "@emotion/react"
import Creatable from "react-select/creatable"
import SearchBar from "../Radiate/SearchBar/SearchBar"

export const MemberSearchContainer = styled.div`
  width: 416px;
  background: ${(props) => props.theme.palerBackground};
  position: absolute;
  left: 0;
  min-width: 416px;
  height: 100%;
  transform: ${props => (!props.show ? "translateX(-416px)" : "translateX(0px)")};
  transition: opacity 0.4s ease, transform 0.4s ease-in-out;
  z-index: 99;

  ${(props) => props.mobile && css`
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.4s ease-in-out;
    transform: ${props.show ? "translateY(0)" : "translateY(100%)"};
    min-width: auto;
  `}
`

export const MemberSearchTopContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid ${(props) => props.theme.gray1};
  background: ${(props) => props.theme.palerBackground};

  .close-member-search-button {
    >.icon {
      font-size: 0.9rem;
    }
  }
`

export const MemberSearchBar = styled(SearchBar)`
  margin-left: 8px;
`

export const DesktopUISearch = styled.div`
  display: block;
  @media (max-width:989px) {
    display: none;
  }
`

export const StyledCreatable = styled(Creatable)`
  width: 100%;
  margin-top: 2px;

`