import styled from "@emotion/styled"
import { Link } from "yarr"
import { setLightness } from "polished"
import Creatable from "react-select"

import PopperContainer from "../../components/Radiate/PopperContainer/PopperContainer"
import ScrollWaypointContainer from "../../components/Radiate/ScrollWaypointContainer/ScrollWaypointContainer"
import SearchBar from "../../components/Radiate/SearchBar/SearchBar"
import ChannelsPicker from "../../components/ChannelsPicker/ChannelsPicker"
import NewBoxButton from "../../components/Radiate/NewBoxButton/NewBoxButton"

export const Overlay = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
  opacity: ${props => props.show ? "1" : "0"};
`

export const FolderContainer = styled.div`
  width: 100%;
  height: calc(100% - 64px);
  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`
export const FolderContainerMobile = styled.div`
  width: 100%;
  display: flex;
  padding: 12px 6px;
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`

export const SideBarContainer = styled.div`
  width: 64px;
  height: 100%;
  border-right: 1px solid ${(props) => props.theme.gray1};
  overflow-y: hidden;
  overflow-x: hidden;
`

export const FolderBox = styled.div`
  width: 48px;
  height: 48px;
  border: ${(props) => props.selected && `3px solid ${setLightness(0.4, props.color)}`};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px;
  border-radius: 12px;
  cursor: pointer;

  &:hover {
    .private-badge {
      opacity: 1;
    }
  }
`

export const FolderBoxMobile = styled.div`
  width: 60px;
  height: 60px;
  border: ${(props) => props.selected && `3px solid ${setLightness(0.4, props.color)}`};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 4px;
  border-radius: 12px;
  cursor: pointer;
  font-size: 24px;
  flex-shrink: 0;

  &:hover {
    .private-badge {
      opacity: 1;
    }
  }

`

export const Folder = styled.div`
  border-radius: 8px;
  width: 38px;
  height: 38px;
  background: ${(props) => props.color && props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;
  flex-shrink: 0;

  &:hover {
    background: ${(props) => (props.color && !props.selected && !props.noHover) && setLightness(0.45, props.color)};
  }
`
export const FolderMobile = styled.div`
  border-radius: 8px;
  width: 50px;
  height: 50px;
  background: ${(props) => props.color && props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;
  flex-shrink: 0;

  &:hover {
    background: ${(props) => (props.color && !props.selected && !props.noHover) && setLightness(0.45, props.color)};
  }
`

export const Badge = styled.div`
  position: absolute;
  right: ${(props) => props.mobile ? "-8px" : "-6px"};
  top: ${(props) => props.mobile ? "-8px" : "-6px"};
  font-size: ${(props) => props.mobile ? "0.8rem" : "0.6rem"};
  font-weight: bold;
  line-height: ${(props) => props.mobile ? "22px" : "18px"};
  padding-left: ${(props) => props.mobile ? "6px" : "4px"};
  padding-right: ${(props) => props.mobile ? "6px" : "4px"};
  min-width: ${(props) => props.mobile ? "22px" : "18px"};
  color: white;
  text-align: center;
  border-radius: ${(props) => props.mobile ? "11px" : "9px"};
  background: ${(props) => props.theme.dangerText};
`

export const PrivateBadge = styled.div`
  position: absolute;
  right: ${(props) => props.mobile ? "-8px" : "-6px"};
  bottom: ${(props) => props.mobile ? "-8px" : "-6px"};
  font-size: ${(props) => props.mobile ? "0.8rem" : "0.6rem"};
  font-weight: bold;
  line-height: ${(props) => props.mobile ? "22px" : "18px"};
  padding-left: ${(props) => props.mobile ? "6px" : "4px"};
  padding-right: ${(props) => props.mobile ? "6px" : "4px"};
  min-width: ${(props) => props.mobile ? "22px" : "18px"};
  color: white;
  text-align: center;
  border-radius: ${(props) => props.mobile ? "11px" : "9px"};
  background: ${(props) => props.theme.gray6};
  opacity: 0;
  transition: all 0.2s linear;
`

export const AddFolderButton = styled.div`
  border-radius: 6px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #833AB4;
  color: #833AB4;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.gray4};
    border-color: ${(props) => props.theme.gray4};
  }
`

export const AddFolderPopperContainer = styled(PopperContainer)`
  margin: 4px 12px 12px 12px;
`

export const EditFolderPopperContainer = styled(PopperContainer)`
  width: calc(100% - 126px);
  flex-shrink: 1;
  height: 56px;
  display: block;
  align-items: center;

  >.popper-display-container {
    width: 100%;
  }
`

export const CreateFolderContainer = styled.div`
  width: 320px;
  height: 199px;

  >.label {
    color: ${(props) => props.theme.gray6};
    padding: 8px;
    font-size: ${(props) => props.theme.textSm};
    font-weight: bold;
  }

  >.separator {
    height: 1px;
    width: 100%;
    background-color: ${(props) => props.theme.gray1};
  }

  .folder-name-text-field {
    margin-top: 8px;
  }

  .error {
    display: ${(props) => !props.showError && "none"};
    font-size: 0.7rem;
    color: ${(props) => props.theme.error};
    text-align: center;
    height: 16px;
  }
`

export const FolderInfoContainer = styled.div`
  width: 320px;
  max-height: ${(props) => `calc(${props?.threadsContainerHeight}px - 8px)`};
  overflow: auto;

  .folder-name {
    font-weight: bold;
  }

  .folder-channels-name {
    white-space: pre-wrap;
    font-size: 0.7rem;
    color: ${(props) => props.theme.gray7};
  }

  .edit-folder-label {
    font-size: ${(props) => props.theme.textSm};
    color: ${(props) => props.theme.gray9};
    padding-bottom: 4px;
    font-weight: bold;
  }

  >.separator {
    height: 1px;
    width: 100%;
    background-color: ${(props) => props.theme.gray1};
  }

  >.error {
    display: ${(props) => !props.showError && "none"};
    font-size: 0.7rem;
    color: ${(props) => props.theme.error};
    padding-top: 2px;
    text-align: center;
  }
`

export const TopBar = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 16px;
  background: #73877B;

  .business-select {
    width: 400px;
  }
`

export const UnauthContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.gray7};
  font-size: 0.8rem;
  flex-direction: column;
  overflow: hidden ;
`

export const StyledLink = styled(Link)`
  text-decoration: none;
  overflow: auto;
  @media (max-width:989px) {
    ::-webkit-scrollbar {
      display: none;
    }
  }

  .swipe-to-delete {
    border-radius: 8px;
  }
`

export const Thread = styled.div`
  height: 100%;
  width: 100%;
  /* width: 336px; */
  border-radius: 8px;
  background: ${(props) => {
    if (props.selected) {
      return setLightness(0.9, props.theme.themeColorGreen)
    } else {
      return "white"
    }
  }};
  padding: ${(props) => props.mobile ? "16px" : "8px"};
  display: flex;
  align-items: center;
  position: relative;

  .short-cut-button {
    opacity: 0;
    transition: all 0.2s linear !important;
  }

  .check-square-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    color: ${(props) => props.theme.gray2};

    &:hover {
      color: ${(props) => setLightness(0.75, props.theme.gray2)};
    }
  }

  .checked-check-square-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    color: ${(props) => props.disableSelect ? setLightness(0.75, props.theme.primary) : props.theme.primary};
    cursor: ${(props) => props.disableSelect ? "auto" : "pointer"};

    &:hover {
      color: ${(props) => !props.disableSelect && setLightness(0.45, props.theme.primary)};
    }
  }

  &:hover {
    .short-cut-button {
      opacity: 0.8;
    }
  }
  @media (max-width:989px) {
    min-width: 100%;
    scroll-snap-align: start;
  }
`

export const MoveToButtonContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 97;
`

export const CustomerDetails = styled.div`
  color: ${(props) => props.theme.gray7};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: ${(props) => props.mobile ? "16px" : "8px"};
  width: ${(props) => {
    if (props.selecting) {
      if (props.mobile) {
        return "calc(100% - 78px)"
      }
      return "calc(100% - 68px)"
    } else {
      if (props.mobile) {
        return "calc(100% - 50px)"
      }
      return "calc(100% - 40px)"
    }
  }};

  .thread-title {
    font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .thread-time-display {
    overflow: hidden;
    white-space: nowrap;
    flex-shrink: 0;
    font-size: ${(props) => props.theme.textSm};
  }

  .channel-name-wrapper {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: ${(props) => props.theme.textSm};
    width: ${(props) => props.selecting ? "calc(272px - 28px)" : "272px"};
    display: flex;
    align-items: center;
    margin: 4px 0;

    >.channel-name {
      padding-left: 2px;
    }
  }

  .thread-last-chat {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: ${(props) => props.theme.textSm};
    width: ${(props) => props.selecting ? "calc(272px - 28px)" : "272px"};
  }

  .thread-unread-count {
    margin-right: 0px;
    font-size: 0.6rem;
    font-weight: bold;
    line-height: 18px;
    padding-left: 4px;
    padding-right: 4px;
    min-width: 18px;
    color: white;
    text-align: center;
    border-radius: 9px;
    background: ${(props) => props.theme.themeColor};
  }
`

export const NoDataContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.gray7};
  font-size: ${(props) => props.theme.textM};
  padding: 24px;
`

export const ThreadsManageContainer = styled.div`
  padding-bottom: 8px;
  padding-top: 8px;

  .count-select-button {
    font-weight: bold;
    width: calc(100% - 16px);
    margin: 0 8px;

    svg {
      width: 14px;
      height: 14px;
    }
  }

  .select-all-button {
    margin-top: 8px;
    font-weight: bold;
    width: calc(100% - 16px);
    margin: 8px 8px 0 8px;

    >span.text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:hover {
      background-color: #ffffff;
      width: 100%;
    }
  }

`

export const StyledScrollWaypointContainer = styled(ScrollWaypointContainer)`
  overflow-y: scroll;
  overflow-x: hidden;
`

export const ThreadsContainer = styled.div`
  height: 100%;
  /* overflow: hidden; */
  width: 100%;
  position: relative;
  .scroll-waypoint {
    height: ${(props) => props.selecting ? "calc(100% - 70px)" : "100%"};
  }
  ::-webkit-scrollbar {
    display: none;
  }
`

export const FolderTemplate = styled.div`
  border-radius: 8px;
  width: 38px;
  height: 38px;
  margin: 16px 13px;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #e6e6e6;
  background: linear-gradient(to right, #f0f0f0 8%, #e6e6e6 38%, #f0f0f0 54%);
  background-size: 640px 640px;

  @keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}
`

export const IconButton = styled(NewBoxButton)`

  .svg-container {
    width: ${(props) => props.mobile ? "24px" : "16px"};
    height: ${(props) => props.mobile ? "24px" : "16px"};
    display: flex;
    justify-content: center;
    align-items: center;
    .svg-icon {
      width: 100%;
      height: 100%;
      filter: ${(props) => {
        if (!props.mobile) {
          return props.$on ? "invert(100%) sepia(100%) saturate(1%) hue-rotate(178deg) brightness(108%) contrast(101%)" : "invert(45%) sepia(0%) saturate(2%) hue-rotate(163deg) brightness(97%) contrast(95%)"
        }

        return "invert(100%) sepia(100%) saturate(1%) hue-rotate(178deg) brightness(108%) contrast(101%)" 
      }};
      opacity: ${(props) => (props.mobile && !props.$on) ? "0.4" : null};
    }
  }
  font-size: ${(props) => {
    switch(props.size) {
      case "L":
        return "24px"
      default:
        return "20px"
    }
  }};
  width: ${(props) => {
    switch(props.size) {
      case "L":
        return "40px"
      default:
        return "34px"
    }
  }};
  height: ${(props) => {
    switch(props.size) {
      case "L":
        return "40px"
      default:
        return "34px"
    }
  }};
  background: ${(props) => {
    if (props.inverted || props.noBG) {
      return "transparent"
    }
    return props.$on ? props.theme.themeColorBlue : props.theme.gray1
  }};
  transition: 0.2s linear background;
  .icon {
    width: ${(props) => {
      switch (props.size) {
        case "L":
          return "20px"
        default:
          return "0.8rem"
      }
    }};
    height: ${(props) => {
      switch (props.size) {
        case "L":
          return "20px"
        default:
          return "0.8rem"
      }
    }};
    transition: 0.2s linear color;
    color: ${(props) => {
      if (props.inverted) {
        return props.$on ? "white" : "rgba(255, 255, 255, 0.4)"
      }
      return props.$on ? "white" : props.theme.gray6}
    };
  }

  &:hover {
    background: ${(props) => {
      if (props.inverted) {
        return "rgba(255, 255, 255, 0.1)"
      }
      return props.$on ? props.theme.themeColorBlue : props.theme.gray05
    }};
    .icon {
      color: ${(props) => {
        if (props.inverted) {
          return props.$on ? "white" : "rgba(255, 255, 255, 0.4)"
        }
        return props.$on ? "white" : props.theme.themeColorBlue
      }};
    }
    .svg-container {
      .svg-icon {
        filter: ${(props) => {

          if (!props.mobile) {
            return props.$on ? "invert(100%) sepia(100%) saturate(1%) hue-rotate(178deg) brightness(108%) contrast(101%)" : "invert(45%) sepia(56%) saturate(7323%) hue-rotate(223deg) brightness(92%) contrast(95%)"
          }
        }
      }
    }
  }
`
export const MobileIconButton = styled.div`
  background: #d3d3d336;
  transition: 0.2s linear background;
  font-size: 20px;
  border-radius: 2px;
  border: 1px solid ${(props) => props.active ? '#fff' : ' #d3d3d336'};
  padding: 5px 8px;
  .icon {
    transition: 0.2s linear color;
    color: #fff;
  }
`

export const SearchBarContainer = styled.div`
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.gray1};
  padding: 12px;

  .member-search-container {
    width: 99%;
    margin-right: 8px;
    position: relative;

    .search-icon, .back-icon {
      color: ${(props) => props.theme.gray4};
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }

    .back-icon {
      cursor: pointer;

      &:hover {
        color: ${(props) => props.theme.gray6};
      }
    }

    .filter-icon-container {
      position: absolute;
      z-index: 99;
      height: 34px;
      width: 36px;
      border-radius: 0 4px 4px 0;
      top: 50%;
      right: 2px;
      transform: translateY(-50%);
      cursor: pointer;
      ${'' /* background: ${(props) => props.theme.gray05}; */}
      display: flex;
      justify-content: center;
      align-items: center;
      .filter-icon {
        color: ${(props) => props.theme.gray4};       
      }
    }
  }
`

export const ChannelFilterContainer = styled.div`
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.gray1};
  padding: 12px;

  .channel-picker-container {
    width: 42%;
    height: 32px;
    position: relative;

    .custom-placeholder {
      color: #fff;
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      font-size: 12px;
    }
  }

  .selected-channels-summary-container {
    display: flex;
    align-items: center;
    max-width: 55%;
    height: 32px;
    border-radius: 4px;
    padding: 0 8px;
    font-size: 12px !important;
    background: #e6e6e6;
    margin-left: 8px;
    >div {
      width: calc(100% - 16px) !important;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .clear-icon {
      margin-left: 8px;
      cursor: pointer;

      :hover {
        color: ${(props) => props.theme.error};
      }
    }
  }
`

export const FolderInfo = styled.div`
  min-height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.gray1};

  >.multi-select-button {
    margin-right: 8px;

    svg {
      width: 14px;
      height: 14px;
    }
  }

  >.folder-name {
    padding-left: 16px;
    color: ${(props) => props.theme.gray7};
  }

  .folder-info-buttons-wrapper {
    display: flex;
    justify-content: flex-end;
    flex-shrink: 0;
    flex-grow: 1;
    margin-right: 8px;
    gap: 8px;
  }
`

export const FolderInfoButton = styled.div`
  width: calc(100% - 126px);
  box-sizing: border-box;
  height: 56px;
  /* cursor: pointer; */
  padding-left: 16px;
  align-items: center;
  display: flex;
  color: ${(props) => props.theme.gray7};
  cursor: pointer;

  >.folder-name {
    box-sizing: border-box;
    padding-right: 8px;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`

export const SettingsButton = styled.div`
  border-radius: 6px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.gray3};
  cursor: pointer;
  margin: 12px;
  text-decoration: none;

  >svg {
    font-size: 20px;
  }

  &:hover {
    background: ${(props) => props.theme.gray2};
    color: #ffffff;
  }
`

export const MemberSearchBar = styled(SearchBar)`
  position: relative;

  input {
    padding-left: 32px !important;
  } 

  
  
`

export const StyledChannelsPicker = styled(ChannelsPicker)`
  flex-grow: 1;
`

export const MemberSearchContainer = styled.div`
  width: 416px;
  background: ${(props) => props.theme.palerBackground};
  position: absolute;
  left: 0;
  overflow-y: hidden;
  min-width: 416px;
  height: 100%;
  transform: ${props => (!props.show ? "translateX(-416px)" : "translateX(0px)")};
  transition: opacity 0.4s ease, transform 0.4s ease-in-out;
  z-index: 99;
`

export const MemberSearchTopContainer = styled.div`
  display: flex;
  padding: 8px;
  border-bottom: 1px solid ${(props) => props.theme.gray1};
  background: ${(props) => props.theme.palerBackground};

  .close-member-search-button {
    >.icon {
      font-size: 0.9rem;
    }
  }
`

export const SearchButton = styled(NewBoxButton)`
  .icon {
    color: white;
  }
  &:hover {
    .icon {
      color: white;
    }
  }
`

export const MemberSearchButton = styled.div`
  background-image: url("/member-search.png");
  width: 34px;
  height: 34px;
  background-size: 20px;
  object-fit: cover;
  background-repeat: no-repeat;
`

export const MemberResultListLoadingContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background: ${(props) => props.theme.palerBackground};
`

export const MoveFolderContainer = styled.div`
  width: 320px;
  height: 145px;

  >.label {
    color: ${(props) => props.theme.gray6};
    padding: 8px;
    font-size: ${(props) => props.theme.textSm};
    font-weight: bold;
  }

  >.separator {
    height: 1px;
    width: 100%;
    background-color: ${(props) => props.theme.gray1};
  }

  .error {
    display: ${(props) => !props.showError && "none"};
    font-size: 0.7rem;
    color: ${(props) => props.theme.error};
    text-align: center;
    height: 16px;
  }
`

export const CustomPopperContainer = styled(PopperContainer)`
  width: 100%;

  >span {
    width: 100%;
  }
  @media (max-width:989px) {
    .popper-dropdown {
      top: 8% !important;
      width: 96%;
      left: 2% !important;
      transform: translate3d(0px, 0px, 0px) !important;
    }
  }
`

export const ThreadsOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 16px;
  opacity: 0.9;
  background: #f1f0f1;
  z-index: 98;
  display: flex;
  justify-content: center;
  align-items: center;


  .overlay-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    text-align: center;

    .overlay-text {
      color: ${(props) => props.theme.gray7};
    }

    .reload-button {
      width: 100%;
    }
  }
`

export const MobileUIInbox = styled.div`
  display: none;
  @media (max-width:989px) {
    display: block;
    width: 100vw;
    overflow: hidden;
  }
`

export const DesktopUIInbox = styled.div`
  display: block;
  @media (max-width:989px) {
    display: none;
  }
`

export const MobileInboxContainer = styled.div`
  width: 100vw;
  max-width: 989px;
  border: none;
  padding-bottom: ${(props) => props.ios ? "16px" : "0"};
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f0f2f7;
`

export const MobileInboxBody = styled.div`
  height: calc(100vh - 185px);
  overflow-y: auto;

  .chatlistCard {
    position: relative;
    padding: 15px 15px 15px 80px;
    .userIcon {
      width: 56px;
      height: 56px;
      color: white;
      font-size: 28px;
      font-weight: 500;
      font-family: "Inter";
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      background-color: #777777;
      position: absolute;
      left: 10px;
      top: 10px;
    }

    h4 {
      font-size: 16px;
      font-weight: 500;
      font-family: "Inter";
      margin: 0 0 10px;
      text-transform: capitalize;
      sup {
        color: #6d46e2;
        margin-left: 5px;
        padding: 3px 8px;
        font-size: 10px;
        font-weight: 500;
        font-family: "Inter";
        border-radius: 25px;
        border: 1px solid #7443df;
        background-image: linear-gradient(to right, #5150ed20, #7c41dc20);
        position: relative;
        top: 3px;
      }
    }
    p {
      margin: 0;
      font-size: 12px;
      font-weight: 500;
      font-family: "Inter";
      color: #777777;
    }

    .chatTime {
      position: absolute;
      right: 10px;
      top: 15px;
      text-align: end;
      h5 {
        font-size: 14px;
        font-weight: 500;
        font-family: "Inter";
        margin: 0 0 13px;
      }
    }
  }

  // Progile Settings
  .profileSettingsHeader {
    display: flex;
    align-items: center;
    img {
      width: 80px;
      height: 80px;
      margin-right: 20px;
      border-radius: 50%;
      object-fit: cover;
    }
    h3 {
      color: var(--greyscale-900, #212121);
      font-size: 20px;
      font-family: Inter;
      font-weight: 700;
      line-height: 160%;
    }
    p {
      color: var(--greyscale-700, #616161);
      font-size: 14px;
      font-family: Inter;
      font-weight: 500;
      line-height: 160%;
      letter-spacing: 0.2px;
    }
  }

  .profileSettingsListArea {
    ul {
      margin: 0;
      padding:  10px 0;
      list-style: none;
      li {
        a {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: var(--greyscale-900, #212121);
          text-decoration: none;
          font-size: 16px;
          font-family: Inter;
          font-weight: 500;
          line-height: 140%;
          letter-spacing: 0.2px;
          margin-top: 20px;
          margin-bottom: 20px;

          svg {
            font-size: 24px;
            position: relative;
            top: 5px;
          }

          span {
            svg {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
`

export const MobileInboxFooter = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 15px 15px 0 0;
  box-shadow: 0 -5px 10px #eeeeee;
  position: relative;
  z-index: 99;
  background-color: white;
  &:before {
    content: "";
    width: 2px;
    height: 50%;
    border-radius: 20px;
    background-color: #77777750;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  button {
    padding: 10px 15px;
    background-color: transparent;
    border: none;
    .chatFooterIcon {
      font-size: 28px;
      margin: 0px;
      color: #777777;
    }
    h5 {
      margin: 0px;
      color: #777777;
      font-size: 14px;
      font-weight: 500;
      font-family: "Inter";
    }
  }

  .activeTab {
    border-bottom : 2px solid #594DE9;
    .chatFooterIcon, h5 {
      color: #7B42DD;
    }
  }
`

export const MobileInboxHeader = styled.div`
  background: #4274ff;
  padding: ${(props) => props.screenType === 'account' ? '15px 15px 15px' : 0};

  .account-header-wrapper {
    display: flex;
    align-items: center;
  }

  button {
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
  }

  h3 {
    margin: 0px;
    color: white;
    font-size: 20px;
    font-weight: 500;
    font-family: "Inter", sans-serif;
  }

  .searchIcon {
    color: white;
    font-size: 20px;
  }

  ul {
    margin: 5px 0 5px 0;
    padding: 0;
    list-style: none;
    display: flex;
    overflow: auto;
    width: inherit;
    gap: 10px;
    ::-webkit-scrollbar{
      display: none;
    }

    li {
      padding: 5px 10px;
      border-radius: 25px;
      margin: auto;
      color: #ffffff;
      border: 2px solid #ffffff;
      font-size: 12px;
      font-weight: 500;
      font-family: "Inter";
      min-width: 90px;
      text-align: center;
      height: 30px;
    }
  }
`

export const ConversationHistoryTransition = styled.div`
    animation: 1s slide-right;
    @keyframes slide-right {
      from {
        margin-left: -100%;
      }
      to {
        margin-left: 0%;
      }
    }
`

export const MentionButton = styled(NewBoxButton)`
  position: relative;

  font-size: ${(props) => {
    switch (props.size) {
      case "L":
        return "24px"
      default:
        return "20px"
    }
  }};
  width: ${(props) => {
    switch (props.size) {
      case "L":
        return "40px"
      default:
        return "34px"
    }
  }};
  height: ${(props) => {
    switch (props.size) {
      case "L":
        return "40px"
      default:
        return "34px"
    }
  }};
  transition: 0.2s linear background;
  .icon {
    width: ${(props) => {
    switch (props.size) {
      case "L":
        return "20px"
      default:
        return "0.8rem"
    }
  }};
    height: ${(props) => {
    switch (props.size) {
      case "L":
        return "20px"
      default:
        return "0.8rem"
    }
  }};
    transition: 0.2s linear color;
    color: ${(props) => {
    if (props.inverted) {
      return props.$on ? "white" : "rgba(255, 255, 255, 0.4)"
    }
    return props.$on ? "white" : props.theme.gray6
  }
  };
  }

  &:hover {
    background: ${(props) => {
      if (props.inverted) {
        return "rgba(255, 255, 255, 0.1)"
      }
      return props.$on ? props.theme.themeColorBlue : props.theme.gray05
    }};
    .icon {
      color: ${(props) => {
        if (props.inverted) {
          return props.$on ? "white" : "rgba(255, 255, 255, 0.4)"
        }
        return props.$on ? "white" : props.theme.themeColorBlue
      }};
        }
      }
    }
  }

  
`

export const MentionButtonContainer = styled.div`
  position: relative;

  .mention-count {
    position: absolute;
    top: 2px;
    right: 2px;
    width: 14px;
    height: 14px;
    border-radius: 7px;

    background: ${(props) => props.theme.dangerText};
    color: white;
    font-size: 0.55rem;
    font-weight: bold;
    line-height: 14px;
    text-align: center;
  }
`

export const StyledCreatable = styled(Creatable)`
  width: 100%;
  margin-top: 2px;

`

export const InboxThreadsContainer = styled.div`
  flex-grow: 1;
  height: calc(100vh - 56px);

  .global-threads {
    display: ${(props) => props.isGlobalThreadsOpen ? "block" : "none"};
  }

  .threads {
    display: ${(props) => props.isGlobalThreadsOpen ? "none" : "block"};
  }

`
