import { useState } from "react"
import { faCheck, faSync, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons"
import { ToastContainer, ToastSuccessIcon, ToastButton, ToastErrorIcon, ToastLoadingIcon, toast  } from "../Radiate/Toaster/Toaster"
import { useMediaQuery } from "../../hooks/useMediaQuery"

function ThreadMoveLoadingToast() {
  return (
    <ToastContainer>
      <ToastLoadingIcon icon={faSync} />
      Moving threads
    </ToastContainer>
  )
}
function ThreadMoveSuccessToast({
  threadsCount = null,
  destinationFolderId,
  setFolderId,
  destinationFolderName,
  toastId,
}) {
  // used to immediately hide custom toast -- react-hot-toast limitation
  const [hideToast, setHideToast] = useState(false)
  const footerHasLittleSpace = useMediaQuery('(max-width: 1300px)', { defaultValue: false })
  const memberSidebarIsOpen =  useMediaQuery('(min-width: 1401px) and (max-width: 1800px)', { defaultValue: false })

  let successText = ""

  if (threadsCount === 1) {
    successText = `${threadsCount} thread has been successfully moved to ${destinationFolderName}`
  } else if (threadsCount > 1 || threadsCount === 0) {
    successText = `${threadsCount} threads have been successfully moved to ${destinationFolderName}`
  } else {
    successText = `All threads have been successfully moved to ${destinationFolderName}`
  }

  if (footerHasLittleSpace || memberSidebarIsOpen) {
    successText = successText.slice(0, 14) + "..."
  }
  return (
    <ToastContainer hideToast={hideToast} style={{ justifyContent: 'space-between' }}>
      <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
        <ToastSuccessIcon icon={faCheck} />
        {successText}
      </div>
      <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap", gap: "8px" }}>
        <ToastButton onClick={() => {
          setFolderId(destinationFolderId)
          toast.dismiss(toastId)
          setHideToast(true)
        }}>Go to Folder</ToastButton>
      </div>
    </ToastContainer>
  )
}
function ThreadMoveConflictToast({ onClick }) {
  return (
    <ToastContainer>
      <ToastErrorIcon icon={faTriangleExclamation} />
      Some threads could not be moved
      <ToastButton onClick={onClick}>View Details</ToastButton>
    </ToastContainer>
  )
}

function ThreadMoveErrorToast({ message }) {
  const footerHasLittleSpace = useMediaQuery('(max-width: 1300px)', { defaultValue: false })
  const memberSidebarIsOpen =  useMediaQuery('(min-width: 1401px) and (max-width: 1800px)', { defaultValue: false })
  let errorText = message?.slice(0, 75) ?? "Something went wrong with moving your threads."
  if (footerHasLittleSpace || memberSidebarIsOpen) {
    errorText = errorText.slice(0, 14) + "..."
  }
  return (
    <ToastContainer>
      <ToastErrorIcon icon={faTriangleExclamation} />
      {errorText}
    </ToastContainer>
  )
}

export {
  ThreadMoveSuccessToast,
  ThreadMoveLoadingToast,
  ThreadMoveConflictToast,
  ThreadMoveErrorToast,
}
