import React from "react";
import * as Styles from "./ThemedModalStyles";
import Modal from "../Modal/Modal";

function ModalContainer({
  isModalOpen = true,
  width,
  height,
  children,
  style,
}) {
  return (
    <Modal open={isModalOpen}>
      {() => (
        <Styles.ModalContainer style={style} width={width} height={height}>
          {children}
        </Styles.ModalContainer>
      )}
    </Modal>
  );
}

function ModalBody({ children, style }) {
  return <Styles.BodyContainer style={style}>{children}</Styles.BodyContainer>;
}

function ModalHeader({ children, style }) {
  return <Styles.Header style={style}>{children}</Styles.Header>;
}

function ModalFooter({ style, children }) {
  return <Styles.ActionBar style={style}>{children}</Styles.ActionBar>;
}

export { ModalContainer, ModalHeader, ModalBody, ModalFooter };
