/**
 * @generated SignedSource<<240d25a72021822a6e56be99f9312673>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": 10,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "getHiddenCount"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lastChatAtFrom"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lastChatAtTo"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "liveChat"
},
v7 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "performingGlobalSearch"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "reverseSort"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tags"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "wChannelIds"
},
v12 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v13 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v14 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v15 = {
  "kind": "Variable",
  "name": "getHiddenCount",
  "variableName": "getHiddenCount"
},
v16 = {
  "kind": "Variable",
  "name": "lastChatAtFrom",
  "variableName": "lastChatAtFrom"
},
v17 = {
  "kind": "Variable",
  "name": "lastChatAtTo",
  "variableName": "lastChatAtTo"
},
v18 = {
  "kind": "Variable",
  "name": "liveChat",
  "variableName": "liveChat"
},
v19 = {
  "kind": "Variable",
  "name": "reverseSort",
  "variableName": "reverseSort"
},
v20 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v21 = {
  "kind": "Variable",
  "name": "tags",
  "variableName": "tags"
},
v22 = {
  "kind": "Variable",
  "name": "wChannelIds",
  "variableName": "wChannelIds"
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v25 = [
  (v13/*: any*/),
  (v14/*: any*/),
  (v15/*: any*/),
  (v16/*: any*/),
  (v17/*: any*/),
  (v18/*: any*/),
  (v19/*: any*/),
  (v20/*: any*/),
  (v21/*: any*/),
  (v22/*: any*/)
],
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "folderId",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GlobalThreadsPaginateQuery",
    "selections": [
      {
        "alias": null,
        "args": (v12/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              {
                "kind": "Variable",
                "name": "performingGlobalSearch",
                "variableName": "performingGlobalSearch"
              },
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "GlobalThreads_userViewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "GlobalThreadsPaginateQuery",
    "selections": [
      {
        "alias": null,
        "args": (v12/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v23/*: any*/),
          (v24/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "role",
                "storageKey": null
              },
              {
                "condition": "performingGlobalSearch",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": (v25/*: any*/),
                    "concreteType": "ThreadConnection",
                    "kind": "LinkedField",
                    "name": "threads",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ThreadEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Thread",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "threadId",
                                "storageKey": null
                              },
                              (v26/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Folder",
                                "kind": "LinkedField",
                                "name": "folder",
                                "plural": false,
                                "selections": [
                                  (v26/*: any*/),
                                  (v27/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "color",
                                    "storageKey": null
                                  },
                                  (v24/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "wMemberId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "wChannelId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Channel",
                                "kind": "LinkedField",
                                "name": "wChannel",
                                "plural": false,
                                "selections": [
                                  (v27/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "integrationIcon",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "integrationName",
                                    "storageKey": null
                                  },
                                  (v24/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "etag",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "title",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "updatedAt",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "lastChatAt",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "lastMessage",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "lastReadAt",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "unreadCount",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Member",
                                "kind": "LinkedField",
                                "name": "member",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "externalProfile",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "MemberProfile",
                                    "kind": "LinkedField",
                                    "name": "inboxProfile",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "firstName",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "lastName",
                                        "storageKey": null
                                      },
                                      (v27/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "profilePicUrl",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  (v24/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v24/*: any*/),
                              {
                                "kind": "ClientExtension",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "newFromInboxAction",
                                    "storageKey": null
                                  }
                                ]
                              },
                              (v23/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "totalCount",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hidden",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v25/*: any*/),
                    "filters": [
                      "search",
                      "wChannelIds",
                      "liveChat",
                      "lastChatAtFrom",
                      "lastChatAtTo",
                      "reverseSort",
                      "tags",
                      "getHiddenCount"
                    ],
                    "handle": "connection",
                    "key": "GlobalThreads_threads",
                    "kind": "LinkedHandle",
                    "name": "threads"
                  }
                ]
              }
            ],
            "type": "UserScope",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d84c08ef14eec7b13202674173a79f46",
    "id": null,
    "metadata": {},
    "name": "GlobalThreadsPaginateQuery",
    "operationKind": "query",
    "text": "query GlobalThreadsPaginateQuery(\n  $after: String\n  $first: Int = 10\n  $getHiddenCount: Boolean\n  $lastChatAtFrom: Long\n  $lastChatAtTo: Long\n  $liveChat: Boolean\n  $performingGlobalSearch: Boolean = false\n  $reverseSort: Boolean\n  $search: String\n  $tags: [String]\n  $wChannelIds: [ID]\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...GlobalThreads_userViewer_39AldC\n    id\n  }\n}\n\nfragment GlobalThreads_userViewer_39AldC on UserScope {\n  role\n  threads(search: $search, wChannelIds: $wChannelIds, liveChat: $liveChat, lastChatAtFrom: $lastChatAtFrom, lastChatAtTo: $lastChatAtTo, first: $first, after: $after, reverseSort: $reverseSort, tags: $tags, getHiddenCount: $getHiddenCount) @include(if: $performingGlobalSearch) {\n    edges {\n      node {\n        threadId\n        folderId\n        folder {\n          folderId\n          name\n          color\n          id\n        }\n        wMemberId\n        wChannelId\n        wChannel {\n          name\n          integrationIcon\n          integrationName\n          id\n        }\n        etag\n        title\n        updatedAt\n        lastChatAt\n        lastMessage\n        lastReadAt\n        unreadCount\n        member {\n          externalProfile\n          inboxProfile {\n            firstName\n            lastName\n            name\n            profilePicUrl\n          }\n          id\n        }\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      totalCount\n      endCursor\n      hasNextPage\n    }\n    hidden\n  }\n  id\n}\n"
  }
};
})();

node.hash = "c24ef18a94d713f79129c21b4a9c7836";

module.exports = node;
