/** @jsxImportSource theme-ui */
import React from "react"
import Select from "react-select"
import { withTranslation } from "react-i18next"
import moment from "moment"
import _ from "lodash"
import PropTypes from "prop-types"
import TimePicker from "rc-time-picker"
import "rc-time-picker/assets/index.css"
import $ from "jquery"
import styled from "@emotion/styled"
import { transparentize } from "polished"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import NewBoxButton from "../Radiate/NewBoxButton/NewBoxButton"
import PopperContainer from "../Radiate/PopperContainer/PopperContainer"

import { randomString } from "../util"
import { css } from "@emotion/react"


const propTypes = {
  defaultFrom: PropTypes.number,
  defaultTo: PropTypes.number,
  onRangeChange: PropTypes.func.isRequired,
  placement: PropTypes.string,
  className: PropTypes.string,
}

const defaultProps = {
  placement: "bottom-end",
  className: ","
}

const CalendarIcon = styled(FontAwesomeIcon)`
  margin-right: 4px;
`

const PickerButton = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${props => props.disabled ? "#f7f7f7" : "#ffffff"};
  border-radius: 4px;
  border: 1px solid ${props => props.disabled ? "#d9d9d9" : props.theme.selectorBorder};
  align-items: center;
  color: ${props => props.disabled ? "#cccccc" :"#1c2e3d"};
  padding: 8px;
  box-sizing: border-box;
  cursor: ${props => props.disabled ? "inherit" : "pointer"};

  &:focus {
    border: 1px solid ${props => props.theme.selectorFocus};
  }

  .date-range {
    flex-grow: 1;
    text-align: start;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .time-display {
      /* padding: 0 24px; */
      margin-right: 2px;
      padding: 2px;
      font-size: 0.85rem;
      color: ${props => props.disabled ? "#808080" : props.theme.gray6};
    }

    >.close-button {
      padding-right: 12px;
      width: 14px;
      height: 14px;
      color: ${props => props.theme.gray4};

      &:hover {
        color: ${props => props.theme.gray6};
      }
    }
  }

  .separator {
    display: inline-block;
    width: 1px;
    height: 20px;
    flex-shrink: 0;
    background-color: ${props => props.theme.selectorBorder};
    flex-grow: 0;
  }

  .fa {
    flex-shrink: 0;
    padding-left: 10.5px;
    color: ${props => props.theme.gray3};
    font-size: 14px;
    flex-grow: 0;
  }
  
`

const Tooltip = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 10px;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);;
  border-radius: 4px 0 4px 4px;
  background-color: #ffffff;
  z-index: 9999;

  @media only screen and (max-width: 690px) {
    flex-direction: column;
    height: ${p => p.mobile ? "100%": "605px"};
  }

  &.hidden {
    display: none;
  }
`

const CalendarSection = styled.div`
  display: flex;
    flex-shrink: 0;
    align-items: center;
    margin-right: 10px;
    height: 100%;

    @media only screen and (max-width: 690px) {
      height: 50%;
    }

    @media only screen and (max-width: 463px) {
      align-self: center;
    }

    .nav-button {
      font-size: 2rem;
    }

    .calendars-container {
      display: flex;
      align-self: flex-start;

      .calendar-container {
        font-size: 0.8rem;
        width: 160px;
        margin: 0 16px;

        &:first-child {
          margin-left: 4px;
        }

        &:last-child {
          margin-right: 4px;
        }

        .month {
          text-align: center;
          padding: 8px 0;
          color: ${props => props.theme.gray6};
          font-size: 0.8rem;
          cursor: pointer;
        }
      }
    }
  }
  
`

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  .day-header-container {
    width: 100%;
    
    >tr {
      border-bottom: 1px solid ${props => props.theme.gray6};
    }

    .day {
      padding: 6px 0;
      text-align: center;
      font-weight: bold;
      width: calc((100% - 7px)/7);
      font-size: 0.6rem;
      color: ${props => props.theme.gray6};
    }
  }
  .dates-container {

    >tr {
      border-bottom: 1px solid ${props => props.theme.gray2};
    }

    .date {
      width: calc(100% /7);
      text-align: center;
      padding: 8px 4px;
      cursor: pointer;
      font-size: 0.8rem;
      color: ${props => props.theme.gray10};

      &.selected {
        background-color:${props => props.theme.themeText};
        color: white;
      }

      &.today {
        font-weight: bold;
      }

      &:hover {
        background-color: ${props => props.theme.warning};
      }

      &.disabled, &.empty, &.future {
        cursor: auto;
        color: ${props => props.theme.gray1};

        &:hover {
          background-color: white;
        }

        &.selected {
          background-color: white;
          color: black;
        }
      }
    }
  }
    
`

const OptionSection = styled.div`
  padding: 8px 8px 8px 0;

  @media only screen and (max-width: 690px) {
    height: 50%;
    align-self: center;
    width: 80%;
    padding: 16px;
  }

  .label {
    font-size: ${props => props.theme.textSm};
    color: ${props => props.theme.gray6};
  }
`

const DateRange = styled.div`
  color: ${props => props.theme.gray6};


  .wrapper {
    display: flex;
    align-items: center;

    .from {
      margin-right: 8px;

      @media only screen and (max-width: 690px) {
        width: 50%;
      }
    }

    .to {
      margin-right: 8px;

      @media only screen and (max-width: 690px) {
        width: 50%;
      }
    }

    .time-picker {
      .rc-time-picker-input {
         margin: 0;
         padding: 2px 4px;
      }
    }
  }
  

  .text {
    font-size: ${props => props.theme.textSm};
    color: ${props => props.theme.gray6};
  }

  input {
    border: 1px solid ${props => props.theme.gray2} ;
    color: ${props => props.theme.gray10};
    height: 32px;
    padding-left: 5px;
    width: 92px;
    box-sizing: content-box;
    font-size: 0.8rem;
    outline: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 8px 0;

    @media only screen and (max-width: 690px) {
      width: 90%;
    }

    &.editing {
      box-shadow: 0 0 0 0.2rem rgba(0,123,255,0.25);
    }

    &.disabled {
      border: 1px solid ${props => props.theme.gray3};
      color: ${props => props.theme.gray6};
    }

    &.error {
      box-shadow: 0 0 0 0.1rem ${props => transparentize(0.1, props.theme.error)};
    }
  }
`
const AlignBottom = styled.div`
  font-size: ${props => props.theme.textSm};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  
  .gmt-text {
    color: ${props => props.theme.gray6};
    padding-top: 2px;
  }

  .limit-text {
    color: ${props => props.theme.error};
  }
`

const ActionBar = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 8px;

  .apply-button {
    margin: 8px 4px;
  }

  .cancel-button {
    margin: 8px 0 8px 4px;
  }

  ${(p) => p.mobile && css`
    flex-direction: column;
    padding: 0;
    gap: 8px;
    .apply-button-mobile {
      width: 250px;
    }
    .cancel-button-mobile {
      margin-top: 16px;
      width: 250px;
    }
  `}
`

function rangeOptionSetter({ from, to, useUTC = false }) {
  const options = {
    today: {
      from: useUTC ? moment().utc().startOf("day").valueOf(): moment().startOf("day").valueOf(),
      to: useUTC ? moment().utc().endOf("day").valueOf() : moment().endOf("day").valueOf(),
    },
    yesterday: {
      from: useUTC ? moment().utc().subtract(1, "day").startOf("day").valueOf() : moment().subtract(1, "day").startOf("day").valueOf(),
      to: useUTC ? moment().utc().subtract(1, "day").endOf("day").valueOf() : moment().subtract(1, "day").endOf("day").valueOf(),
    },
    lastWeek: {
      from: useUTC ? moment().utc().subtract(1, "week").startOf("week").valueOf(): moment().subtract(1, "week").startOf("week").valueOf(),
      to: useUTC ? moment().utc().subtract(1, "week").endOf("week").valueOf() : moment().subtract(1, "week").endOf("week").valueOf(),
    },
    lastMonth: {
      from: useUTC ? moment().utc().subtract(1, "month").startOf("month").valueOf(): moment().subtract(1, "month").startOf("month").valueOf(),
      to: useUTC ? moment().utc().subtract(1, "month").endOf("month").valueOf(): moment().subtract(1, "month").endOf("month").valueOf(),
    },
    last7Days: {
      from: useUTC ? moment().utc().subtract(7, "day").startOf("day").valueOf(): moment().subtract(7, "day").startOf("day").valueOf(),
      to: useUTC ? moment().utc().subtract(1, "day").endOf("day").valueOf(): moment().subtract(1, "day").endOf("day").valueOf(),
    },
    last30Days: {
      from: useUTC ? moment().utc().subtract(30, "day").startOf("day").valueOf(): moment().subtract(30, "day").startOf("day").valueOf(),
      to: useUTC ? moment().utc().subtract(1, "day").endOf("day").valueOf(): moment().subtract(1, "day").endOf("day").valueOf(),
    },
    all: {
      from: null,
      to: null,
    },
  }
  let result = "custom"
  _.forEach(options, (value, key) => {
    if (value.from === from && value.to === to) {
      result = key
      return false
    }
  })
  return result
}

function getDisplay(from, to, placeholder, useUTC = false, t) {
  let display
  const presetDisplay = rangeOptionSetter({
    from,
    to,
    useUTC,
  })

  const last_7_days = t?.("date_range_picker.last_7_days")
  const yesterday = t?.("date_range_picker.yesterday")
  const today = t?.("date_range_picker.today")
  const last_week = t?.("date_range_picker.last_week")
  const last_month = t?.("date_range_picker.last_month")
  const last_30_days = t?.("date_range_picker.last_30_days")

  switch (presetDisplay) {
    case "custom":
      if (from && to) {
        if (useUTC) {
          display = `${t?.("date_range_picker.custom_from_date", { date: moment.utc(from) })} - ${t?.("date_range_picker.custom_to_date", { date: moment.utc(to) })}`
        } else {
          display = `${t?.("date_range_picker.custom_from_date", { date: moment(from) })} - ${t?.("date_range_picker.custom_to_date", { date: moment(to) })}`
        }
      } else {
        display = placeholder
      }
      break
    case "last7Days":
      display = last_7_days
      break
    case "yesterday":
      display = yesterday
      break
    case "today":
      display = today
      break
    case "lastWeek":
      display = last_week
      break
    case "lastMonth":
      display = last_month
      break
    case "last30Days":
      display = last_30_days
      break
    case "all":
      display = placeholder
      break
    default:
      break
  }
  return {
    display,
    presetDisplay
  }
}

const DateRangePicker = class DateRangePicker extends React.Component {
  constructor(props) {
    super(props)
    let from = null
    let display = this.props.t?.("date_range_picker.select_placeholder")
    if (props.from) {
      from = props.from
    } else if (props.defaultFrom) {
      from = props.defaultFrom
    }

    let to = null
    if (props.to) {
      to = props.to
    } else if (props.defaultTo) {
      to = props.defaultTo
    }

    const useUTC = this.props?.useUTC ?? false
    const displayResult = getDisplay(from, to, props.placeholder ?? this.props.t?.("date_range_picker.select_placeholder"), useUTC, this.props.t)
    display = displayResult.display
    this.state = {
      rangeOption: rangeOptionSetter({
        from,
        to,
        useUTC,
      }),
      from,
      to,
      display,
      monthOffset: 0,
      editing: "from",
      showTooltip: false,
      viewportWidth: null,
      fromText: from === null ? "" : (useUTC ? moment.utc(from).format("ll"): moment(from).format("ll")),
      toText: to === null ? "" : (useUTC ? moment.utc(to).format("ll") : moment(to).format("ll")),
      fromError: false,
      toError: false
    }
  }

  componentDidMount() {
    this.setState({
      viewportWidth: window.innerWidth
    })
    window.onresize = (e) => {
      this.setState({
        viewportWidth: window.innerWidth
      })
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (this.props.from !== nextProps.from || this.props.to !== nextProps.to) {
      const change = {}
      if (this.props.from !== nextProps.from) {
        change.from = nextProps.from
      }
      if (this.props.to !== nextProps.to) {
        change.to = nextProps.to
      }
      this.setState(change)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.from !== this.state.from || prevState.to !== this.state.to) {
      const change = {}
      if (prevState.from !== this.state.from) {
        change.fromText = this.state.from === null ? "" : (
          this.props.useUTC
            ? moment.utc(this.state.from).format("ll")
            : moment(this.state.from).format("ll")
        )
      }
      if (prevState.to !== this.state.to) {
        change.toText = this.state.to === null ? "" : (
          this.props.useUTC
            ? moment.utc(this.state.to).format("ll")
            : moment(this.state.to).format("ll")
        )
      }
      const useUTC = this.props?.useUTC ?? false
      const displayResult = getDisplay(this.state.from, this.state.to, this.props.placeholder ?? this.props.t?.("date_range_picker.select_placeholder"), useUTC, this.props.t)
      change.display = displayResult.display
      change.rangeOption = displayResult.presetDisplay
      this.setState(change)
    }
  }

  dateComponent(timestamp) {
    const { from, to, editing } = this.state
    const useUTC = this.props.useUTC ?? false
    let selectedClass = ""
    let availabilityClass = ""
    let todayClass = ""
    let futureClass = ""

    const timestampStartOfDay = useUTC
      ? moment.utc(timestamp).startOf("day").valueOf()
      : moment(timestamp).startOf("day").valueOf()

    if (timestamp >= from && timestampStartOfDay <= to) {
      selectedClass = "selected"
    }
    if (editing === "to" && timestamp < from) {
      availabilityClass = "disabled"
    }

    const startOfDay = useUTC
      ? moment.utc().startOf("day").valueOf()
      : moment().startOf("day").valueOf()

    const endOfDay = useUTC
      ? moment.utc().endOf("day").valueOf()
      : moment().endOf("day").valueOf()

    if (timestamp >= startOfDay && timestamp <= endOfDay) {
      todayClass = "today"
    }
    if (timestamp > endOfDay) {
      futureClass = "future"
    }
    return (
      <td
        key={randomString(16)}
        className={`date ${selectedClass} ${availabilityClass} ${todayClass} ${futureClass}`}
        onClick={() => {
          const useUTC = this.props?.useUTC ?? false
          if (this.state.editing === "from") {
            let newFrom
            if (this.state.from) {
              if (useUTC) {
                newFrom = moment.utc(this.state.from)
              } else {
                newFrom = moment(this.state.from)
              }
            } else {
              if (useUTC) {
                newFrom = moment.utc().startOf("day")
              } else {
                newFrom = moment().startOf("day")
              }
            }
            if (useUTC) {
              newFrom.year(moment.utc(timestamp).year())
              newFrom.month(moment.utc(timestamp).month())
              newFrom.date(moment.utc(timestamp).date())
            } else {
              newFrom.year(moment(timestamp).year())
              newFrom.month(moment(timestamp).month())
              newFrom.date(moment(timestamp).date())
            }

            this.setState({
              from: newFrom.valueOf(),
              editing: "to",
              rangeOption: "custom",
            })
          } else {
            let newTo
            if (this.state.to) {
              if (useUTC) {
                newTo = moment.utc(this.state.to)
              } else {
                newTo = moment(this.state.to)
              }
            } else {
              if (useUTC) {
                newTo = moment.utc().endOf("day")
              } else {
                newTo = moment().endOf("day")
              }
            }

            if (useUTC) {
              newTo.year(moment.utc(timestamp).year())
              newTo.month(moment.utc(timestamp).month())
              newTo.date(moment.utc(timestamp).date())
            } else {
              newTo.year(moment(timestamp).year())
              newTo.month(moment(timestamp).month())
              newTo.date(moment(timestamp).date())
            }

            this.setState({
              to: newTo.valueOf(),
              editing: "from",
              rangeOption: "custom",
            })
          }
        }}
      >{useUTC ? moment.utc(timestamp).format("D") : moment(timestamp).format("D")}
      </td>
    )
  }

  calendar(time, t) {
    const daysInMonth = time.daysInMonth()
    let daysBeforeStart = time.startOf("month").day()
    let daysAfterStart = 6 - time.endOf("month").day()

    const daysBeforeStartComponents = []
    while (daysBeforeStart > 0) {
      daysBeforeStartComponents.push(
        <td key={randomString(16)} className="date empty"></td>
      )
      daysBeforeStart--
    }

    const datesComponents = []
    for (let i = 1; i <= daysInMonth; i++) {
      datesComponents.push(this.dateComponent(time.date(i).endOf("day").valueOf()))
    }
    const daysAfterStartComponents = []
    while (daysAfterStart > 0) {
      daysAfterStartComponents.push(
        <td key={randomString(16)} className="date empty"></td>
      )
      daysAfterStart--
    }

    const dates = [...daysBeforeStartComponents, ...datesComponents, ...daysAfterStartComponents]
    const dateRows = []
    for (let i = 0; i < dates.length; i += 7) {
      const rowComponents = dates.slice(i, i + 7)
      dateRows.push(
        <tr key={randomString(16)}>
          {rowComponents}
        </tr>
      )
    }
    return (
      <div key={randomString(16)} className="calendar-container">
        <div className="month">{t?.("date_range_picker.month_and_year", { date: time })}</div>
        <Table>
          <thead className="day-header-container">
            <tr>
              <th className="day">{t?.("date_range_picker.sunday_first_letter")}</th>
              <th className="day">{t?.("date_range_picker.monday_first_letter")}</th>
              <th className="day">{t?.("date_range_picker.tuesday_first_letter")}</th>
              <th className="day">{t?.("date_range_picker.wednesday_first_letter")}</th>
              <th className="day">{t?.("date_range_picker.thursday_first_letter")}</th>
              <th className="day">{t?.("date_range_picker.friday_first_letter")}</th>
              <th className="day">{t?.("date_range_picker.saturday_first_letter")}</th>
            </tr>
          </thead>
          <tbody className="dates-container">
            {dateRows}
          </tbody>
        </Table>
      </div>
    )
  }

  showIcon() {
    if (!this.state.from || !this.state.to) {
      return (
        <CalendarIcon
          className="calendar-icon"
          icon="calendar"
          fixedWidth
        />
      )
    }
    return null
  }

  showClearButton(isClearable, rangeOption) {
    if (isClearable && rangeOption !== "all") {
      return (
        <FontAwesomeIcon
          className="close-button"
          icon="times"
          onClick={(e) => {
            e.stopPropagation()
            this.setState({
              from: null,
              to: null,
              fromText: "",
              toText: "",
              display: this.props.t?.("date_range_picker.select_placeholder")
            })
            this.props.onRangeChange({
              from: null,
              to: null
            })
          }}
        />
      )
    }
    return null
  }

  calMaxDayRange() {
    const maxDayRangeByMs = this.props.maxDayRange * 24 * 60 * 60 * 1000
    if (moment(this.state.to).diff(this.state.from) > maxDayRangeByMs) {
      return true
    }
    return false
  }

  render() {
    const t = this.props.t
    const {
      placement,
      className,
      disabled,
      maxDayRange,
      top,
      left,
      useUTC,
      mobile = false,
    } = this.props
    const {
      display, rangeOption
    } = this.state
    const cMonth = useUTC
      ? moment.utc().add(this.state.monthOffset, "months")
      : moment().add(this.state.monthOffset, "months")
    const pMonth = useUTC
      ? moment.utc().add(-1 + this.state.monthOffset, "months")
      : moment().add(-1 + this.state.monthOffset, "months")
    
    // const ppMonth = moment().add(-2 + this.state.monthOffset, "months")
    let dateInputRangeOptionClass = "disabled"
    if (rangeOption === "custom") {
      dateInputRangeOptionClass = ""
    }

    let calendars = []

    if (mobile) {
      calendars = [
        this.calendar(cMonth, t),
      ]
    } else if (this.state.viewportWidth < 463) {
      calendars = [
        this.calendar(cMonth, t),
      ]
    } else {
      calendars = [
        // this.calendar(ppMonth),
        this.calendar(pMonth, t),
        this.calendar(cMonth, t),
      ]
    }

    const options = [{
      label: t?.("date_range_picker.please_select"),
      value: "all"
    }, {
      label: t?.("date_range_picker.today"),
      value: "today"
    }, {
      label: t?.("date_range_picker.yesterday"),
      value: "yesterday"
    }, {
      label: t?.("date_range_picker.last_week"),
      value: "lastWeek"
    }, {
      label: t?.("date_range_picker.last_month"),
      value: "lastMonth"
    }, {
      label: t?.("date_range_picker.last_7_days"),
      value: "last7Days"
    }, {
      label: t?.("date_range_picker.last_30_days"),
      value: "last30Days"
    }, {
      label: t?.("date_range_picker.custom"),
      value: "custom"
    }]
    return (
      <PopperContainer
        top={top}
        left={left}
        show={this.state.showTooltip}
        placement={placement}
        displayDisplay="block"
        positionFixed
        modifiers={{
          offset: {
            enabled: true,
            offset: "0 2px"
          }
        }}
        disabled={disabled}
        className={`date-range-picker-container ${className}`}
        onClickOutside={(e) => {
          if (!$(e.target).closest(".time-picker-pop-up").length) {
            if (this.props.maxDayRange && this.calMaxDayRange(maxDayRange)) {
              this.setState({
                showTooltip: false,
                to: this.props.to,
                from: this.props.from
              })
            } else {
              this.setState({
                showTooltip: false,
              })
            }
          }
        }}
        display={(
          <PickerButton
            disabled={disabled}
            className={`picker-button ${this.props.className || ""}`}
            onClick={() => {
              if (disabled) {
                return
              }
              this.setState({
                showTooltip: true
              })
            }}
          >
            <div className="date-range">
              <span className="time-display">
                {this.showIcon()}
                {display}
              </span>
              {this.showClearButton(this.props.isClearable, rangeOption)}
            </div>
            <div className="separator" />
            <FontAwesomeIcon
              className="fa"
              icon={`${this.state.showTooltip ? "chevron-up" : "chevron-down"}`}
              fixedWidth
            />
          </PickerButton>
        )}
      >
        {() => (
          <Tooltip
            className="tooltip-container"
            mobile={mobile}
          >
            <CalendarSection>
              <NewBoxButton
                primary
                borderless
                className="nav-button prev-button"
                icon="caret-left"
                onClick={() => {
                  this.setState({
                    monthOffset: this.state.monthOffset - 1
                  })
                }}
              />
              <div className="calendars-container">
                {calendars}
              </div>
              <NewBoxButton
                primary
                borderless
                className="nav-button next-button"
                icon="caret-right"
                onClick={() => {
                  this.setState({
                    monthOffset: this.state.monthOffset + 1
                  })
                }}
              />
            </CalendarSection>
            {mobile ? null : (
              <OptionSection
                className="option-section-container"
              >
                <div className="align-top">
                  <div className="field">
                    <div className="label">{t?.("date_range_picker.date_range")}:</div>
                    <Select
                      className="date-range-selector"
                      value={options.find(o => o.value === this.state.rangeOption)}
                      defaultValue={options.find(o => o.value === "today")}
                      onChange={(o) => {
                        const useUTC = this.props?.useUTC ?? false
                        const { value } = o
                        switch (value) {
                          case "custom":
                            this.setState({
                              rangeOption: "custom",
                            })
                            break
                          case "today":
                            this.setState({
                              rangeOption: "today",
                              from: useUTC ? moment.utc().startOf("day").valueOf(): moment().startOf("day").valueOf(),
                              to: useUTC ? moment.utc().endOf("day").valueOf() : moment().endOf("day").valueOf(),
                              monthOffset: 0,
                              editing: "from",
                            })
                            break
                          case "yesterday":
                            this.setState({
                              rangeOption: "yesterday",
                              from: useUTC ? moment.utc().subtract(1, "day").startOf("day").valueOf() : moment().subtract(1, "day").startOf("day").valueOf(),
                              to: useUTC ? moment.utc().subtract(1, "day").endOf("day").valueOf(): moment().subtract(1, "day").endOf("day").valueOf(),
                              monthOffset: 0,
                              editing: "from",
                            })
                            break
                          case "lastWeek":
                            this.setState({
                              rangeOption: "lastWeek",
                              from: useUTC ? moment.utc().subtract(1, "week").startOf("week").valueOf() : moment().subtract(1, "week").startOf("week").valueOf(),
                              to: useUTC ? moment.utc().subtract(1, "week").endOf("week").valueOf() : moment().subtract(1, "week").endOf("week").valueOf(),
                              monthOffset: 0,
                              editing: "from",
                            })
                            break
                          case "lastMonth":
                            this.setState({
                              rangeOption: "lastMonth",
                              from: useUTC ? moment.utc().subtract(1, "month").startOf("month").valueOf() : moment().subtract(1, "month").startOf("month").valueOf(),
                              to: useUTC ? moment.utc().subtract(1, "month").endOf("month").valueOf() : moment().subtract(1, "month").endOf("month").valueOf(),
                              monthOffset: 0,
                              editing: "from",
                            })
                            break
                          case "last7Days":
                            this.setState({
                              rangeOption: "last7Days",
                              from: useUTC ? moment.utc().subtract(7, "day").startOf("day").valueOf() : moment().subtract(7, "day").startOf("day").valueOf(),
                              to: useUTC ? moment.utc().subtract(1, "day").endOf("day").valueOf() : moment().subtract(1, "day").endOf("day").valueOf(),
                              monthOffset: 0,
                              editing: "from",
                            })
                            break
                          case "last30Days":
                            this.setState({
                              rangeOption: "last30Days",
                              from: useUTC ? moment.utc().subtract(30, "day").startOf("day").valueOf() : moment().subtract(30, "day").startOf("day").valueOf(),
                              to: useUTC ? moment.utc().subtract(1, "day").endOf("day").valueOf() : moment().subtract(1, "day").endOf("day").valueOf(),
                              monthOffset: 0,
                              editing: "from",
                            })
                            break
                          case "all":
                            this.setState({
                              rangeOption: "all",
                              from: null,
                              to: null,
                              monthOffset: 0,
                              editing: "from",
                            })
                            break
                          default:
                        }
                      }}
                      options={options}
                      styles={{
                        container: provided => ({
                          ...provided,
                          margin: "8px 0"
                        }),
                        singleValue: provided => ({
                          ...provided,
                          fontSize: "0.9rem",
                          color: "#2e2e2e"
                        }),
                        menuList: provided => ({
                          ...provided,
                          fontSize: "0.9rem",
                          color: "#2e2e2e"
                        })
                      }}
                    />
                  </div>
                  <DateRange className="date-range-container">
                    <div className="text">{t?.("date_range_picker.from")}:</div>
                    <div className="wrapper">
                      <div
                        className="from"
                        onClick={() => {
                          this.setState({
                            editing: "from",
                            rangeOption: "custom",
                          })
                        }}
                      >
                        <input
                          type="text"
                          placeholder={t?.("date_range_picker.date")}
                          className={`date from-date ${dateInputRangeOptionClass} ${this.state.editing === "from" ? "editing" : ""} ${this.state.fromError ? "error" : ""}`}
                          disabled={this.state.editing !== "from"}
                          value={this.state.fromText}
                          onChange={(e) => {
                            const { value } = e.target
                            this.setState({
                              fromText: value,
                            })
                          }}
                          onBlur={(e) => {
                            const { value } = e.target
                            const useUTC = this.props?.useUTC ?? false
                            let error = false
                            error = useUTC ? !moment.utc(value, "ll").isValid() : !moment(value, "ll").isValid()

                            if (!error) {
                              const startOfDay = useUTC
                                ? moment.utc(value, "ll").startOf("day").valueOf()
                                : moment(value, "ll").startOf("day").valueOf()
                              if (startOfDay > this.state.to) {
                                error = true
                              }
                            }

                            if (!error) {
                              this.setState({
                                fromText: useUTC ? moment.utc(value, "ll").format("ll") : moment(value, "ll").format("ll"),
                                from: useUTC ? moment.utc(value, "ll").startOf("day").valueOf() : moment(value, "ll").startOf("day").valueOf(),
                                fromError: false,
                              })
                            } else {
                              this.setState({
                                fromError: true,
                              })
                            }
                          }}
                        />
                      </div>
                      <TimePicker
                        className="time-picker"
                        popupClassName="time-picker-pop-up"
                        popupStyle={{
                          zIndex: "9999"
                        }}
                        showSecond={false}
                        value={this.state.from ? (useUTC ? moment.utc(this.state.from) : moment(this.state.from)) : null}
                        use12Hours
                        placeholder={t?.("date_range_picker.time")}
                        allowEmpty={false}
                        onChange={(o) => {
                          if (this.state.from) {
                            const newFrom = useUTC ? moment.utc(this.state.from) : moment(this.state.from)
                            newFrom.hour(o.hour())
                            newFrom.minutes(o.minutes())
                            this.setState({
                              from: newFrom.valueOf()
                            })
                          } else {
                            this.setState({
                              from: o.valueOf()
                            })
                          }
                        }}
                      >
                      </TimePicker>
                    </div>

                  </DateRange>
                  <DateRange>
                    <div className="text">{t?.("date_range_picker.to")}:</div>
                    <div className="wrapper">
                      <div
                        className="to"
                        onClick={() => {
                          this.setState({
                            editing: "to",
                            rangeOption: "custom",
                          })
                        }}
                      >
                        <input
                          type="text"
                          placeholder={t?.("date_range_picker.date")}
                          className={`date to-date ${dateInputRangeOptionClass} ${this.state.editing === "to" ? "editing" : ""} ${this.state.toError ? "error" : ""}`}
                          value={this.state.toText}
                          disabled={this.state.editing !== "to"}
                          onChange={(e) => {
                            const { value } = e.target
                            this.setState({
                              toText: value,
                            })
                          }}
                          onBlur={(e) => {
                            const { value } = e.target
                            let error = false
                            error = useUTC ? !moment.utc(value, "ll").isValid() : !moment(value, "ll").isValid()

                            if (!error) {
                              const endOfDay = useUTC
                                ? moment.utc(value, "ll").endOf("day").valueOf()
                                : moment(value, "ll").endOf("day").valueOf()
                              if (endOfDay < this.state.from) {
                                error = true
                              }
                            }

                            if (!error) {
                              const toText = useUTC ? moment.utc(value, "ll").format("ll") : moment(value, "ll").format("ll")
                              const to = useUTC ? moment.utc(value, "ll").endOf("day").valueOf() : moment(value, "ll").endOf("day").valueOf()
                              this.setState({
                                toText: toText,
                                to: to,
                                toError: false,
                              })
                            } else {
                              this.setState({
                                toError: true,
                              })
                            }
                          }}
                        />
                      </div>
                      <TimePicker
                        className="time-picker"
                        popupClassName="time-picker-pop-up"
                        popupStyle={{
                          zIndex: "9999"
                        }}
                        showSecond={false}
                        value={this.state.to ? (useUTC ? moment.utc(this.state.to) : moment(this.state.to)): null}
                        use12Hours
                        allowEmpty={false}
                        placeholder={t?.("date_range_picker.time")}
                        onChange={(o) => {
                          if (this.state.to) {
                            const newTo = useUTC ? moment.utc(this.state.to) : moment(this.state.to)
                            newTo.hour(o.hour())
                            newTo.minutes(o.minutes())
                            this.setState({
                              to: newTo.valueOf()
                            })
                          } else {
                            this.setState({
                              to: o.valueOf()
                            })
                          }
                        }}
                      >
                      </TimePicker>
                    </div>
                  </DateRange>
                </div>
                <AlignBottom className="align-bottom">
                  <div className="gmt-text">GMT{useUTC ? moment.utc().format("Z") : moment().format("Z")}</div>
                  {this.calMaxDayRange(maxDayRange) && (
                    <div className="limit-text">{t?.("date_range_picker.cannot_exceed_range", { maxDayRange })}</div>
                  )}
                  <ActionBar className="action-bar">
                    <NewBoxButton
                      primary
                      disabled={this.calMaxDayRange(maxDayRange) || this.state.fromError || this.state.toError}
                      className="apply-button"
                      text={t?.("date_range_picker.date_range_apply")}
                      onClick={() => {
                        if (maxDayRange && this.calMaxDayRange(maxDayRange)) {
                          return
                        }
                        let display
                        switch (this.state.rangeOption) {
                          case "custom":
                            const customDisplay = useUTC
                              ? `${t?.("date_range_picker.custom_from_date", { date: moment.utc(this.state.from) })} - ${t?.("date_range_picker.custom_to_date", { date: moment.utc(this.state.to) })}`
                              : `${t?.("date_range_picker.custom_from_date", { date: moment(this.state.from)} )} - ${t?.("date_range_picker.custom_to_date", { date: moment(this.state.to) })}`
                            display = customDisplay
                            break
                          case "last7Days":
                            display = t?.("date_range_picker.last_7_days")
                            break
                          case "yesterday":
                            display = t?.("date_range_picker.yesterday")
                            break
                          case "today":
                            display = t?.("date_range_picker.today")
                            break
                          case "lastWeek":
                            display = t?.("date_range_picker.last_week")
                            break
                          case "lastMonth":
                            display = t?.("date_range_picker.last_month")
                            break
                          case "last30Days":
                            display = t?.("date_range_picker.last_30_days")
                            break
                          case "all":
                            display = this.props.placeholder ?? t?.("date_range_picker.select_placeholder")
                            break
                          default:
                            break
                        }
                        this.setState({
                          showTooltip: false,
                          display
                        })
                        this.props.onRangeChange({
                          from: this.state.from,
                          to: this.state.to,
                        })
                      }}
                    />
                    <NewBoxButton
                      className="cancel-button"
                      text={t?.("date_range_picker.cancel")}
                      onClick={() => {
                        this.setState({
                          showTooltip: false,
                          from: this.props.from,
                          to: this.props.to,
                        })
                      }}
                    />
                  </ActionBar>
                </AlignBottom>
              </OptionSection>
            )}
            {mobile ? (
              <ActionBar mobile={mobile} className="action-bar">
                <NewBoxButton
                  className="cancel-button-mobile"
                  text={t?.("date_range_picker.cancel")}
                  onClick={() => {
                    this.setState({
                      showTooltip: false,
                      from: this.props.from,
                      to: this.props.to,
                    })
                  }}
                />
                <NewBoxButton
                  primary
                  disabled={this.calMaxDayRange(maxDayRange) || this.state.fromError || this.state.toError}
                  className="apply-button-mobile"
                  text={t?.("date_range_picker.date_range_apply")}
                  onClick={() => {
                    if (maxDayRange && this.calMaxDayRange(maxDayRange)) {
                      return
                    }
                    let display
                    switch (this.state.rangeOption) {
                      case "custom":
                        const customDisplay = useUTC
                          ? `${t?.("date_range_picker.custom_from_date", { date: moment.utc(this.state.from) })} - ${t?.("date_range_picker.custom_to_date", { date: moment.utc(this.state.to) })}`
                          : `${t?.("date_range_picker.custom_from_date", { date: moment(this.state.from)} )} - ${t?.("date_range_picker.custom_to_date", { date: moment(this.state.to) })}`
                        display = customDisplay
                        break
                      case "last7Days":
                        display = t?.("date_range_picker.last_7_days")
                        break
                      case "yesterday":
                        display = t?.("date_range_picker.yesterday")
                        break
                      case "today":
                        display = t?.("date_range_picker.today")
                        break
                      case "lastWeek":
                        display = t?.("date_range_picker.last_week")
                        break
                      case "lastMonth":
                        display = t?.("date_range_picker.last_month")
                        break
                      case "last30Days":
                        display = t?.("date_range_picker.last_30_days")
                        break
                      case "all":
                        display = this.props.placeholder ?? t?.("date_range_picker.select_placeholder")
                        break
                      default:
                        break
                    }
                    this.setState({
                      showTooltip: false,
                      display
                    })
                    this.props.onRangeChange({
                      from: this.state.from,
                      to: this.state.to,
                    })
                  }}
                />
              </ActionBar>
            ) : null}
          </Tooltip>
        )}
      </PopperContainer>
    )
  }
}

DateRangePicker.defaultProps = defaultProps
DateRangePicker.propTypes = propTypes

export default withTranslation("common", { withRef: true })(DateRangePicker)
