import React from "react";
import { Toaster as ReactHotToaster, toast } from "react-hot-toast";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faSync, faCheck } from "@fortawesome/free-solid-svg-icons";
import theme from "../../../stylesheets/theme";
import { darken } from "polished";

const SPACING = 8;

const TEXT_COLOR = "#FFFFFF";

const ERROR_ICON_COLOR = theme.dangerText
const ERROR_BG_COLOR = "#000000";

const SUCCESS_BG_COLOR = "#000000";
const SUCCESS_ICON_COLOR = theme.success

const LOADING_BG_COLOR = "#000000";
const LOADING_ICON_COLOR = "#eab308"

const FONT_SIZE = "14px";

const ToastContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding-left: 8px;
  padding-right: 8px;
`;

const ErrorIcon = styled(FontAwesomeIcon)`
  color: ${ERROR_ICON_COLOR};
`;

const SuccessIcon = styled(FontAwesomeIcon)`
  color: ${SUCCESS_ICON_COLOR};
`;

const LoadingIcon = styled(FontAwesomeIcon)`
  color: ${LOADING_ICON_COLOR};
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const BUTTON_TEXT_COLOR = "#3B82F6";
const BUTTON_HOVER_BACKGROUND = "rgba(59, 130, 246, 0.1)";

const ToastButton = styled.button`
  padding-bottom: 0;
  background: transparent;
  color: ${BUTTON_TEXT_COLOR};
  border: none;
  border-radius: 4px;
  font-size: ${FONT_SIZE};
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${BUTTON_HOVER_BACKGROUND};
  }

  &:active {
    background-color: ${darken(0.1, BUTTON_HOVER_BACKGROUND)};
  }
`;

function Toaster() {
  return (
    <ReactHotToaster
      reverseOrder={true}
      position="bottom-center"
      gutter={SPACING}
      containerStyle={{
      }}
      toastOptions={{
        className: "react-hot-toast",
        duration: 8000,
        style: {
          color: TEXT_COLOR,
          padding: `${SPACING}px`,
          borderRadius: "4px",
          fontSize: FONT_SIZE,
          visibility: "visible",
        },
        error: {
          icon: (
            <ToastContainer>
              <ErrorIcon icon={faExclamationTriangle} color={TEXT_COLOR} />
            </ToastContainer>
          ),
          style: {
            background: ERROR_BG_COLOR,
          },
        },
        success: {
          icon: (
            <ToastContainer>
              <SuccessIcon icon={faCheck} color={TEXT_COLOR} />
            </ToastContainer>
          ),
          style: {
            background: SUCCESS_BG_COLOR,
          },
        },
        loading: {
          icon: (
            <ToastContainer>
              <LoadingIcon icon={faSync} color={TEXT_COLOR} />
            </ToastContainer>
          ),
          style: {
            background: LOADING_BG_COLOR,
          },
        },
      }}
    />
  );
}

const TOAST_HEIGHT = "40px"
// shifting is needed to handle the case when the notes sidebar collapses and reopens
const SHIFT_BREAKPOINT =  1400
const SHIFT_AMOUNT = 190

const CustomToastContainer = styled.div`
  display: flex;
  position: relative;
  top: 4px;
  gap: ${SPACING}px;
  justify-content: center;
  align-items: center;
  min-height: ${TOAST_HEIGHT};
  background: ${SUCCESS_BG_COLOR};
  color: ${TEXT_COLOR};
  padding: ${SPACING/2}px ${SPACING * 2}px;
  border-radius: 4px;
  opacity: 0;
  animation: fadeInFromTop 0.5s forwards;
  visibility: ${p => p.hideToast ? "hidden": "visible"};
  @keyframes fadeInFromTop {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @media (max-width: ${SHIFT_BREAKPOINT}px) {
    left: ${SHIFT_AMOUNT}px;
  }
`;

export {
  Toaster,
  toast,

  CustomToastContainer as ToastContainer,
  SuccessIcon as ToastSuccessIcon,
  ErrorIcon as ToastErrorIcon,
  LoadingIcon as ToastLoadingIcon,
  ToastButton,
};
