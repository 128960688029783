import React, { PureComponent } from "react"
import { withTranslation } from "react-i18next"
import { Flex } from "theme-ui"
import moment from "moment"

import * as Styles from "../../pages/Inbox/InboxStyles"
import IconContainer from "../../components/IconContainer/IconContainer"
import IntegrationIconDisplay from "../../components/IntegrationIconDisplay/IntegrationIconDisplay"

import { renderUserNameInboxProfileFirst } from "../../components/util"

class Row extends PureComponent {
  renderThread({ threadObj, t }) {
    const {
      mobile = false,
    } = this.props.data


    function getLastChatTimeText(momentDate) {
      const REFERENCE = moment()
      const TODAY = REFERENCE.clone().startOf('day');
      const YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');
      const A_WEEK_OLD = REFERENCE.clone().subtract(6, 'days').startOf('day');

      function isToday(momentDate) {
        return momentDate.isSame(TODAY, 'd');
      }

      function isYesterday(momentDate) {
        return momentDate.isSame(YESTERDAY, 'd');
      }
      function isWithinAWeek(momentDate) {
        return momentDate.isAfter(A_WEEK_OLD);
      }

      if (isToday(momentDate)) {
        return t?.("threads.last_chat_at_today", { time: momentDate })
      } else if (isYesterday(momentDate)) {
        return t?.("threads.last_chat_at_yesterday", { time: momentDate })
      } else if (isWithinAWeek(momentDate)) {
        return t?.("threads.last_chat_at_this_week", { time: momentDate })
      }
      return t?.("threads.last_chat_at_fallback", { time: momentDate })
    }

    return (
      <Styles.Thread
        mobile={mobile}
        selected={false}
        disableSelect
      >
        <IconContainer
          url={threadObj.member?.inboxProfile?.profilePicUrl}
          size={mobile ? "SM" : "S"}
          name={renderUserNameInboxProfileFirst({
            externalProfile: threadObj?.member?.externalProfile,
            inboxProfile: threadObj?.member?.inboxProfile
          })}
        />
        <Styles.CustomerDetails selecting={false} mobile={mobile}>
          <Flex sx={{ justifyContent: "space-between", alignItems: "center" }}>
            <div className="thread-title" title={threadObj?.title}>
              {threadObj?.title}
            </div>
            {threadObj?.lastChatAt ?
              <>
                <div
                  className="thread-time-display"
                >
                  {getLastChatTimeText(moment(threadObj?.lastChatAt))}
                </div>
              </>
              :
              null
            }
          </Flex>
          <Flex
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="channel-name-wrapper" >
              <IntegrationIconDisplay
                icon={threadObj.wChannel?.integrationIcon}
                name={threadObj.wChannel?.integrationName}
                size="16px"
              />
              <div className="channel-name">{threadObj.wChannel?.name}</div>
            </div>
          </Flex>

          <div className="thread-last-chat">{threadObj?.lastMessage}</div>
        </Styles.CustomerDetails>
      </Styles.Thread>
    )
  }

  render() {
    const {
      thread,
    } = this.props.data
    const t = this.props.t
    return (
      this.renderThread({ threadObj: thread, t })
    )
  }
}

const ThreadRow = withTranslation("common", { withRef: true })(Row)

export {
  ThreadRow
}
