import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import {
  ModalContainer,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../Radiate/ThemedModal/ThemedModal";
import NewBoxButton from "../Radiate/NewBoxButton/NewBoxButton";
import theme from "../../stylesheets/theme";
import { ThreadRow } from "./ThreadConflictRow";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../contexts/UserContext";
import { useMutation } from "react-relay";
import { ThreadsUpdateThreadsFolderMutation } from "../ManageThreadsPopper/ManageThreadsPopperQuery";
import { toast } from "react-hot-toast";
import { ThreadMoveErrorToast, ThreadMoveSuccessToast } from "./MoveThreads";
import { BackgroundTaskContext } from "../../contexts/BackgroundTaskContext ";

const SPACING_SMALL = "8px";
const MODAL_WIDTH = "700px";
const MODAL_HEIGHT = "600px";
const BORDER_RADIUS = "8px";
const RIGHT_SECTION_WIDTH = "200px";
const SELECT_WIDTH = "160px";

const ThreadList = styled.div`
  padding-top: ${SPACING_SMALL};
  max-height: 400px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: ${SPACING_SMALL};
  max-width: 385px;
`;

const StyledBodyText = styled.p`
  font-size: ${theme.textSm};
  margin: 0;
  line-height: 1.5;
  color: ${theme.gray6};
`;

const Container = styled.div`
  display: flex;
  background-color: ${theme.themeColorLightFocus};
  border-radius: ${BORDER_RADIUS};
  margin-top: ${SPACING_SMALL};
`;

const LeftSection = styled.div`
  flex: 1;
  padding: 16px;
`;

const RightSection = styled.div`
  width: ${RIGHT_SECTION_WIDTH};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
`;

const Title = styled.h3`
  font-weight: bold;
  margin: 0 0 ${SPACING_SMALL} 0;
  color: ${theme.gray6};
`;

const SubText = styled.p`
  margin: 0;
  font-size: ${theme.textSm};
  color: ${theme.gray5};
  line-height: 1.4;
`;

const Separator = styled.hr`
  border: none;
  height: 1px;
  background-color: ${theme.gray2};
  margin: ${SPACING_SMALL} 0;
  width: 100%;
`;

const StyledSelect = styled(Select)`
  width: ${SELECT_WIDTH};
`;

const generateOptionsList = ({ isSuperAdmin, isAdmin }) => {
  const options =
    isSuperAdmin || isAdmin
      ? [
          { value: "skip", label: "Skip" },
          { value: "link", label: "Link" },
        ]
      : [{ value: "skip", label: "Skip" }];

  return options;
};

const ToggleThreadsButton = styled.button`
  background: none;
  border: none;
  color: ${theme.themeColor};
  font-size: ${theme.textSm};
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-top: ${SPACING_SMALL};
  padding-bottom: ${SPACING_SMALL};

  &:hover {
    text-decoration: underline;
  }
  svg {
    padding-left: ${SPACING_SMALL};
  }
`;

const GroupedThreadConflicts = ({
  conflict,
  destinationFolderName = null,
  onResolutionChange,
}) => {
  const { isSuperAdmin, isAdmin } = useContext(UserContext);
  const [selectedOption, setSelectedOption] = useState(
    generateOptionsList({ isSuperAdmin, isAdmin })[0],
  );
  const [showThreads, setShowThreads] = useState(false);
  const { t } = useTranslation();

  const handleChange = (option) => {
    setSelectedOption(option);
    onResolutionChange(conflict.channelId, option.value);
  };

  const toggleThreads = () => {
    setShowThreads(!showThreads);
  };

  const hiddenCount = conflict?.hiddenCount
  const totalCount = conflict?.totalCount

  return (
    <Container>
      <LeftSection>
        <Title>{conflict.channelName}</Title>
        <SubText>
          {conflict.totalCount}{" "}
          {conflict.totalCount === 1 ? "thread" : "threads"} could not be moved
          to folder {destinationFolderName} because the channel{" "}
          {conflict.channelName} is not linked to folder {destinationFolderName}
          .
        </SubText>
        {hiddenCount > 0 && (
          <SubText style={{ paddingTop: "8px" }}>
            <strong>{totalCount}</strong> of <strong>{totalCount + hiddenCount}</strong> threads which will be moved are shown below.
          </SubText>
        )}
        <Separator />
        <ToggleThreadsButton onClick={toggleThreads}>
          {showThreads ? "Hide threads" : "Display threads"}
          <FontAwesomeIcon icon={showThreads ? faAngleUp : faAngleDown} />
        </ToggleThreadsButton>
        {showThreads && (
          <ThreadList>
            {conflict.threads.map((thread, index) => (
              <ThreadRow
                key={`${thread.threadId}-${index}`}
                data={{
                  thread: thread,
                }}
                index={index}
                style={{ height: "92px", width: "100%" }}
                t={t}
              />
            ))}
          </ThreadList>
        )}
      </LeftSection>
      <RightSection>
        <StyledSelect
          value={selectedOption}
          onChange={handleChange}
          options={generateOptionsList({ isSuperAdmin, isAdmin })}
          defaultValue={generateOptionsList({ isSuperAdmin, isAdmin })[0]}
        />
      </RightSection>
    </Container>
  );
};

let clientMutationId = 0;
function createUpdateThreadsInput({
  threadIds,
  originFolderId,
  folderId,
  conflictResolutions,
  threadsFilter,
  wChannelIds,
  selectedAllThreads,
  backgroundTaskId,
}) {
  const input = {
    clientMutationId: clientMutationId++,
    conflictResolutions: Object.values(conflictResolutions),
    folderId,
    userOption: "conflictResolution",
  };

  if (backgroundTaskId) {
    input.backgroundTaskId = backgroundTaskId;
  } else if (threadIds?.length > 0) {
    input.threadIds = threadIds;
  } else if (selectedAllThreads) {
    input.originFolderId = originFolderId;
    if (wChannelIds) {
      input.wChannelIds = wChannelIds;
    }
  } else if (threadsFilter) {
    input.threadsFilter = threadsFilter;
  }
  return input;
}

const MovingTheadsDescription = () => {
  const { isSuperAdmin, isAdmin } = useContext(UserContext);
  if (isAdmin || isSuperAdmin) {
    return (
      <StyledBodyText>
        You can choose to <strong>"skip"</strong>, and the threads will remain
        in their current location, or <strong>"link"</strong> a channel to a
        folder, and the threads will be moved.{" "}
        <em>Please note only admin can perform the link action.</em>
      </StyledBodyText>
    );
  }
  return (
    <StyledBodyText>
      You can choose to <strong>"skip"</strong>, and the conflicting threads
      will remain in their current location.
      <em> Please note only admin can perform the link action.</em>
    </StyledBodyText>
  );
};

function getInitialConflictState(conflictData) {
  if (!conflictData || !_.isArray(conflictData.conflicts)) {
    return {};
  }
  const conflicts = conflictData.conflicts;
  const result = {};

  for (const c of conflicts) {
    result[c.channelId] = { userAction: "skip", wChannelId: c.channelId };
  }

  return result;
}

const MovingThreadsConflictModal = ({
  isOpen,
  onClose,
  conflictData,
  destinationFolderName,
  threadIds,
  originFolderId,
  folderId,
  threadsFilter,
  wChannelIds,
  setFolderId = () => {},
  selectedAllThreads,
  resetToasts = () => {},
  backgroundTaskId,
}) => {
  const { setBackgroundTaskId, setIsBackgroundTaskPending } = useContext(BackgroundTaskContext)
  const [conflictResolutions, setConflictResolutions] = useState({});
  const [commitMutation, updateThreadsInFlight] = useMutation(
    ThreadsUpdateThreadsFolderMutation,
  );

  const handleResolutionChange = (channelId, action) => {
    setConflictResolutions((prev) => ({
      ...prev,
      [channelId]: { userAction: action, wChannelId: channelId },
    }));
  };

  useEffect(() => {
    if (conflictData?.conflicts?.length > 0) {
      setConflictResolutions(getInitialConflictState(conflictData));
    }
  }, [JSON.stringify(conflictData)]);

  const handleSubmit = () => {
    const input = createUpdateThreadsInput({
      threadIds,
      wChannelIds,
      originFolderId,
      folderId,
      threadsFilter,
      selectedAllThreads,
      backgroundTaskId,
      conflictResolutions: Object.values(conflictResolutions),
    });

    commitMutation({
      variables: { input },
      onCompleted: (response, errors) => {
        if (errors) {
          resetToasts()
          console.error("Error updating threads folder:", errors);
        } else {
          const isError = response?.updateThreadsFolder.ok !== 1
          const error = response?.updateThreadsFolder?.error?.message

          const destinationFolderName = response?.updateThreadsFolder?.destinationFolder?.name;
          const destinationFolderId = response?.updateThreadsFolder?.destinationFolder?.folderId;
          const threadsMovedCount = response?.updateThreadsFolder?.threads?.length;

          const isBackgroundTask = Boolean(response?.updateThreadsFolder?.backgroundTaskId)
          const backgroundTaskId = response?.updateThreadsFolder?.backgroundTaskId

          if (isError) {
            resetToasts()
            onClose()
            toast.custom(
              <ThreadMoveErrorToast message={error} />,
              {
                duration: 8000
              }
            )
            return
          }

          if (isBackgroundTask) {
            resetToasts()
            setIsBackgroundTaskPending(() => true)
            setBackgroundTaskId(() => backgroundTaskId)
            onClose()
            return
          }

          resetToasts()
          toast.custom(
            <ThreadMoveSuccessToast
              setFolderId={setFolderId}
              threadsCount={threadsMovedCount}
              destinationFolderId={destinationFolderId}
              destinationFolderName={destinationFolderName}
            />,
            {
              duration: 8000,
            },
          );
          onClose();
        }
      },
      onError: (err) => {
        console.error("Error updating threads folder:", err);
        const errorMsg = err.res?.json?.errors[0]?.message || err;
        alert(errorMsg);
      },
    });
  };

  return (
    <ModalContainer
      isModalOpen={isOpen}
      width={MODAL_WIDTH}
      height={MODAL_HEIGHT}
    >
      <ModalHeader style={{ fontSize: "20px", fontWeight: "bold" }}>
        Moving threads conflict
      </ModalHeader>
      <ModalBody style={{ paddingTop: "16px" }}>
        <MovingTheadsDescription />
        {conflictData &&
          conflictData.conflicts.map((conflict) => (
            <GroupedThreadConflicts
              key={conflict.channelId}
              conflict={conflict}
              destinationFolderName={destinationFolderName}
              onResolutionChange={handleResolutionChange}
            />
          ))}
      </ModalBody>
      <ModalFooter>
        <NewBoxButton text="Cancel" onClick={onClose} />
        <NewBoxButton
          text="Submit"
          onClick={handleSubmit}
          primary
          style={{ marginLeft: SPACING_SMALL }}
          loading={updateThreadsInFlight}
        />
      </ModalFooter>
    </ModalContainer>
  );
};

export default MovingThreadsConflictModal;
