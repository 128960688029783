/** @jsxImportSource theme-ui */
import React, { useEffect, useState, useTransition, useCallback, useRef, PureComponent, useContext, startTransition } from "react"
import { useTranslation, withTranslation } from "react-i18next"
import { useHistory } from "yarr"
import { Flex, Text } from "theme-ui"
import AutoSizer from "react-virtualized-auto-sizer"
import { FixedSizeList as List } from "react-window"
import InfiniteLoader from "react-window-infinite-loader"
import _ from "lodash"
import qs from "query-string"
import moment from "moment"
import graphql from "babel-plugin-relay/macro"
import SwipeToDelete from "react-swipe-to-delete-ios"
import {
  usePaginationFragment,
  useMutation
} from "react-relay"
import ContentLoader from "react-content-loader"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faFilter, faInbox, faRotateRight, faSearch, faX } from "@fortawesome/free-solid-svg-icons"
import { faSquare, faSquareCheck } from "@fortawesome/free-regular-svg-icons"
import Highlighter from "react-highlight-words"
import { Box } from "theme-ui"

import theme from "../../stylesheets/theme"
import * as Styles from "./InboxStyles"
import IconContainer from "../../components/IconContainer/IconContainer"
import IntegrationIconDisplay from "../../components/IntegrationIconDisplay/IntegrationIconDisplay"
import NewBoxButton from "../../components/Radiate/NewBoxButton/NewBoxButton"
import ReactTooltip from "../../components/ReactTooltip/ReactTooltip"
import { renderUserNameInboxProfileFirst } from "../../components/util"
import { UserContext } from "../../contexts/UserContext"
import FolderPicker from "../../components/FolderPicker/FolderPicker"
import MovingThreadsConflictModal from "../../components/MoveThreads/MovingThreadsConflictModal"

import { ThreadsUpdateThreadsFolderMutation } from "../../components/ManageThreadsPopper/ManageThreadsPopperQuery"
import { toast } from "react-hot-toast"
import { ThreadMoveSuccessToast } from "../../components/MoveThreads/MoveThreads"
import { BackgroundTaskContext } from "../../contexts/BackgroundTaskContext "
import { Overlay } from "./InboxStyles"
import * as MobileStyles from "../../components/ThreadSearch/ThreadSearchStyles"
import PopperContainer from "../../components/Radiate/PopperContainer/PopperContainer"
import ChannelsPicker from "../../components/ChannelsPicker/ChannelsPicker"
import DateRangePicker from "../../components/DateRangePicker/DateRangePicker"
import styled from "styled-components"

const GUTTER_SIZE = 8

const Loader = ({
  mobile = false,
  ...props
}) => (
  <ContentLoader
    speed={2}
    height={mobile ? 96 : 88}
    viewBox="0 0 100% 88"
    backgroundColor="#e6e6e6"
    foregroundColor="#f0f0f0"
    {...props}
  >
    <circle cx={mobile ? "49" : "36"} cy={mobile ? "46" : "42"} r={mobile ? "25" : "20"} />
    <rect x={mobile ? "90" : "62"} y={mobile ? "26" : "22"} rx="3" ry="3" width="180" height="6" />
    <rect x={mobile ? "90" : "62"} y={mobile ? "43" : "39"} rx="3" ry="3" width="100" height="6" />
    <rect x={mobile ? "90" : "62"} y={mobile ? "60" : "56"} rx="3" ry="3" width="150" height="6" />
  </ContentLoader>
)

const innerElementType = React.forwardRef(({ style, ...rest }, ref) => (
  <div
    ref={ref}
    style={{
      ...style,
      height: `${parseFloat(style.height) + (GUTTER_SIZE * 2)}px`,
      // paddingLeft: GUTTER_SIZE,
      // paddingTop: GUTTER_SIZE,
    }}
    {...rest}
  />
))

const MobileGlobalSearchCloseWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  cursor: pointer;
  width: 20px;
  .icon {
    color: ${theme.gray4};
    &:hover {
      color: ${theme.gray6};
    }
  }
`

const minimumBatchSize = 30

function constructUpdateThreadMutationVariables({
  folderId,
  filter,
  search,
  threadMode,
  from,
  to,
  selectedThreadsIds,
}) {
  let clientMutationId = 0
  const variables = {
    folderId,
    clientMutationId: clientMutationId++,
    returnConflictsGroupedByChannel: true,
  }

  if (!folderId) throw new Error("destination folder ID must be provided")
  if (selectedThreadsIds?.length > 0) {
    variables.threadIds = selectedThreadsIds
  } else {
    if (filter || search) {
      variables.threadsFilter = {
        folderIds: null,
        search: search ?? null,
        tags: filter?.tags ?? null,
        lastChatAtFrom: from ?? null,
        lastChatAtTo: to ?? null,
        wChannelIds: filter?.wChannelIds ?? null
      }
      if (threadMode === "isBot") {
        variables.threadsFilter.liveChat = false
      } else if (threadMode === "isLiveChat") {
        variables.threadsFilter.liveChat = true
      }
    }
  }
  return variables
}

class Row extends PureComponent {
  renderThread({ threadObj, t }) {
    const {
      mobile,
      updateThreadFolder,
      threadId,
      selectedAllThreads,
      selecting,
      onChangeThreadId,
      selectedThreads,
      setSelectedThreads,
      isInFlightThreadFolder,
      search,
      setIsMobileGlobalSearchOpen,
      setSearch,
      setFilter,
      setChannelIdsFilter,
    } = this.props.data


    function getLastChatTimeText(momentDate) {
      const REFERENCE = moment()
      const TODAY = REFERENCE.clone().startOf('day');
      const YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');
      const A_WEEK_OLD = REFERENCE.clone().subtract(6, 'days').startOf('day');

      function isToday(momentDate) {
        return momentDate.isSame(TODAY, 'd');
      }

      function isYesterday(momentDate) {
        return momentDate.isSame(YESTERDAY, 'd');
      }
      function isWithinAWeek(momentDate) {
        return momentDate.isAfter(A_WEEK_OLD);
      }

      if (isToday(momentDate)) {
        return t?.("threads.last_chat_at_today", { time: momentDate })
      } else if (isYesterday(momentDate)) {
        return t?.("threads.last_chat_at_yesterday", { time: momentDate })
      } else if (isWithinAWeek(momentDate)) {
        return t?.("threads.last_chat_at_this_week", { time: momentDate })
      }
      return t?.("threads.last_chat_at_fallback", { time: momentDate })
    }

    return (
      <Styles.Thread
        mobile={mobile}
        selected={threadId === threadObj?.threadId}
        folderId={threadObj?.folderId}
        disableSelect={selectedAllThreads}
        newFromInboxAction={threadObj?.newFromInboxAction}
        onClick={(e) => {
          e.preventDefault()
          if (!selecting) {
            onChangeThreadId(threadObj?.threadId)
            if (mobile) {
              setIsMobileGlobalSearchOpen?.(false)
              setSearch("")
              setFilter(null)
              setChannelIdsFilter([])
            }
          } else {
            const selected = selectedThreads.find((st) => st === threadObj.threadId) || selectedAllThreads
            if (selectedAllThreads) {
              return
            }
            let array
            if (selected) {
              array = selectedThreads.filter(o => o !== threadObj.threadId)
            } else {
              if (selectedThreads?.length >= 100) {
                const alertSelectedThreadsCannotExceed100 = t("threads.alert_selected_threads_cannot_exceed_100")
                alert(alertSelectedThreadsCannotExceed100)
                return
              }
              array = [...selectedThreads, threadObj.threadId]
            }
            setSelectedThreads(array)
          }
        }}
      >
        {selecting && (
          <>
            {selectedThreads.find((st) => st === threadObj.threadId) || selectedAllThreads ?
              <FontAwesomeIcon
                className="checked-check-square-icon"
                icon={faSquareCheck}
              />
              :
              <FontAwesomeIcon
                className="check-square-icon"
                icon={faSquare}
              />
            }
          </>
        )}
        <IconContainer
          url={threadObj.member?.inboxProfile?.profilePicUrl}
          size={mobile ? "SM" : "S"}
          name={renderUserNameInboxProfileFirst({
            externalProfile: threadObj?.member?.externalProfile,
            inboxProfile: threadObj?.member?.inboxProfile
          })}
        />
        <Styles.CustomerDetails selecting={selecting} mobile={mobile}>
          <Flex sx={{ justifyContent: "space-between", alignItems: "center" }}>
            <div className="thread-title" title={threadObj?.title}>
              <Highlighter
                searchWords={[search]}
                textToHighlight={threadObj?.title}
                highlightStyle={{
                  background: theme.gray2,
                  color: theme.gray7
                }}
              />
            </div>
            {threadObj?.lastChatAt ?
              <>
                <div
                  className="thread-time-display"
                  data-tooltip-id={threadObj?.threadId}
                  data-tooltip-content={t?.("threads.last_chat_at_fulltime_fallback", { time: moment(threadObj?.lastChatAt) })}
                >
                  {getLastChatTimeText(moment(threadObj?.lastChatAt))}
                </div>
                <ReactTooltip
                  id={threadObj?.threadId}
                  place="right"
                />
              </>
              :
              null
            }
          </Flex>
          <Flex
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="channel-name-wrapper" >
              <IntegrationIconDisplay
                icon={threadObj.wChannel?.integrationIcon}
                name={threadObj.wChannel?.integrationName}
                size="16px"
              />
              <div className="channel-name">{threadObj.wChannel?.name}</div>
            </div>
            {(threadObj?.unreadCount || 0) > 0 ?
              <div className="thread-unread-count">{threadObj.unreadCount > 99 ? "99+" : threadObj.unreadCount}</div>
              :
              null
            }
          </Flex>

          <div className="thread-last-chat">{threadObj?.lastMessage}</div>
        </Styles.CustomerDetails>
        <Styles.DesktopUIInbox>
          {!selecting ?
            (
              <Styles.MoveToButtonContainer className="short-cut-button">
                {/* <NewBoxButton
                  sx={{
                    minWidth: "28px",
                    minHeight: "28px",
                    borderRadius: 0,
                    borderBottomRightRadius: "8px",
                    ".icon": {
                      color: "#707070"
                    },
                    "&:hover": {
                      ".icon": {
                        color: "#707070"
                      }
                    }
                  }}
                  icon={faEllipsis}
                  borderless
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                  }}
                /> */}
                <NewBoxButton
                  sx={{
                    minWidth: "28px",
                    minHeight: "28px",
                    background: threadObj?.folderId === "done" ? "#6544f4" : "hsl(94, 48%, 56%)",
                    borderRadius: 0,
                    borderBottomRightRadius: "8px",
                    ".icon": {
                      color: "#ffffff"
                    },
                    "&:hover": {
                      background: threadObj?.folderId === "done" ? "#6544f4" : "hsl(94, 48%, 56%)",
                      ".icon": {
                        color: "#ffffff"
                      }
                    }
                  }}
                  icon={threadObj?.folderId === "done" ? faInbox : faCheck}
                  loading={isInFlightThreadFolder}
                  disabled={isInFlightThreadFolder}
                  borderless
                  data-tooltip-content={threadObj?.folderId === "done" ? t?.("threads.move_to_main") : t?.("threads.move_to_done")}
                  data-tooltip-id="thread-option-tooltip"
                  onClick={(e) => {
                    updateThreadFolder({
                      etag: threadObj.etag,
                      folderId: threadObj?.folderId === "done" ? "main" : "done",
                      selectedThreadId: threadObj?.threadId
                    })
                    e.stopPropagation()
                    e.preventDefault()
                  }}
                />
              </Styles.MoveToButtonContainer>
            ) : null
          }
        </Styles.DesktopUIInbox>
      </Styles.Thread>
    )
  }

  render() {
    const {
      threads,
      location,
      query,
      mobile,
      setTempDeletedThreads,
      updateThreadFolder,
      hasNext,
    } = this.props.data
    const {
      index,
      style,
    } = this.props
    const isItemLoaded = !hasNext || index < threads?.length
    if (!isItemLoaded) {
      return <Loader mobile={mobile} style={style} />
    }
    const threadObj = threads?.[index]?.node

    const t = this.props.t
    return (
      <Styles.StyledLink
        to={{
          ...location,
          search: qs.stringify({
            ...query ?? {},
            thread: threadObj?.threadId
          })
        }}
        style={{
          ...style,
          left: style.left + GUTTER_SIZE,
          top: style.top + GUTTER_SIZE,
          width: `calc(100% - ${GUTTER_SIZE * 2}px)`,
          height: style.height - GUTTER_SIZE,
          overflow: "hidden",
        }}
        onClick={(e) => {
          e.preventDefault()
        }}
      >
        {mobile ? (
          <SwipeToDelete
            onDelete={() => {
              setTempDeletedThreads((t) => {
                return [...t, threadObj.threadId]
              })
              updateThreadFolder({
                etag: threadObj.etag,
                folderId: threadObj?.folderId === "done" ? "main" : "done",
                selectedThreadId: threadObj?.threadId
              })
            }}
            height={mobile ? 92 : 84}
            deleteColor={threadObj?.folderId === "done" ? "#0084FF" : "hsl(94, 48%, 56%)"}
            deleteText={(
              <div>
                <FontAwesomeIcon icon={threadObj?.folderId === "done" ? faInbox : faCheck} />
                <div
                  sx={{
                    fontSize: "12px"
                  }}
                >{threadObj?.folderId === "done" ? "Main" : "Done"}</div>
              </div>
            )}
            disabled={!mobile}
            id={threadObj?.threadId}
            className="swipe-to-delete"
          >
            {this.renderThread({ threadObj, t })}
          </SwipeToDelete>
        ) : this.renderThread({ threadObj, t })}
      </Styles.StyledLink>
    )
  }
}

const RowWithTranslatedLastChatAt = withTranslation("common", { withRef: true })(Row)

const GlobalThreads = ({
  channelIds,
  reverseSort,
  folderName,
  folderId,
  search,
  filter,
  onChangeThreadId,
  selecting,
  isFilteringUnread,
  onFinishUpdateThreadsFolder,
  threadId,
  mobile = false,
  className,
  onHiddenThreadsCountChange = () => {},
  threadMode,
  from,
  to,
  setFolderId,
  setSearch,
  setFilter,
  setChannelIdsFilter,
  isGlobalThreadsOpen,
  processedFilter,
  setOpenFilterOptions,
  openFilterOptions,
  channelIdsFilter,
  memberTagsOption,
  generalStyles,
  selectedTags,
  setSelectedTags,
  setFrom,
  setTo,
  threadModeOptions,
  setThreadMode,
  isMobileGlobalSearchOpen,
  setIsMobileGlobalSearchOpen,
  ...props
}) => {
  const { isSuperAdmin, isSuperAuditor } = useContext(UserContext)
  const { t } = useTranslation("common")
  const { location } = useHistory()
  const query = qs.parse(location.search)
  const [selectedThreads, setSelectedThreads] = useState([])
  const [tempDeletedThreads, setTempDeletedThreads] = useState([])
  const [selectedAllThreads, setSelectedAllThreads] = useState(false)
  const [showMoveFolderDropDown, setShowMoveFolderDropDown] = useState(false)
  const [showRefetchOverlay, setShowRefetchOverlay] = useState(false)
  const infiniteLoaderRef = useRef(null)
  const [loading, startTransition] = useTransition()
  const [selectedFolder, setSelectedFolder] = useState(null)

  const [commitThreadFolder, isInFlightThreadFolder] = useMutation(
    ThreadsUpdateThreadsFolderMutation
  )

  useEffect(() => {
    setSelectedThreads([])
    setTempDeletedThreads([])
    setSelectedAllThreads(false)
  }, [folderId, isFilteringUnread, selecting])

  const {
    data,
    loadNext,
    hasNext,
    isLoadingNext,
    refetch
  } = usePaginationFragment(
    graphql`
      fragment GlobalThreads_userViewer on UserScope
      @argumentDefinitions(
        search: { type: "String" }
        first: { type: "Int", defaultValue: 10 }
        after: { type: "String" }
        wChannelIds: { type: "[ID]" }
        liveChat: { type: "Boolean" }
        lastChatAtFrom: { type: "Long" }
        lastChatAtTo: { type: "Long" }
        reverseSort: { type: "Boolean" }
        tags: { type: "[String]" }
        getHiddenCount: { type: "Boolean" }
        performingGlobalSearch: { type: "Boolean", defaultValue: false }
      )
      @refetchable(queryName: "GlobalThreadsPaginateQuery") {
        role
        threads (
          search: $search
          wChannelIds: $wChannelIds
          liveChat: $liveChat
          lastChatAtFrom: $lastChatAtFrom
          lastChatAtTo: $lastChatAtTo
          first: $first
          after: $after
          reverseSort: $reverseSort
          tags: $tags
          getHiddenCount: $getHiddenCount
        ) @include(if: $performingGlobalSearch) @connection(key: "GlobalThreads_threads") {
          edges {
            node {
              threadId
              folderId
              folder {
                folderId
                name
                color
              }
              wMemberId
              wChannelId
              wChannel {
                name
                integrationIcon
                integrationName
              }
              etag
              title
              updatedAt
              lastChatAt
              lastMessage
              lastReadAt
              unreadCount
              title
              member {
                externalProfile
                inboxProfile {
                  firstName
                  lastName
                  name
                  profilePicUrl
                }
              }
              newFromInboxAction
            }
          }
          pageInfo {
            totalCount
          }
          hidden
        }
      }
    `,
    props.data.userViewer
  )

  onHiddenThreadsCountChange(data?.threads?.hidden ?? null)

  const debounceSearch = useCallback(_.debounce(({
    search,
    filter,
    reverseSort
  }) => {
    let variable = {
      search,
      wChannelIds: filter?.wChannelIds,
      tags: filter?.tags || [],
      lastChatAtFrom: filter?.updatedAt?.from,
      lastChatAtTo: filter?.updatedAt?.to,
      reverseSort,
      getHiddenCount: filter?.tags?.length > 0 ? true : false,
      performingGlobalSearch: true,
    }
    if (filter?.threadMode === "isBot") {
      variable.liveChat = false
    } else if (filter?.threadMode === "isLiveChat") {
      variable.liveChat = true
    }
    startTransition(() => {
      refetch(variable, {
        fetchPolicy: "network-only",
      })
    })
  }, 1000), [])

  useEffect(() => {
    if (search) {
      debounceSearch({
        search,
        filter,
        reverseSort,
      })
    } else {
      let variable = {
        search,
        wChannelIds: filter?.wChannelIds,
        tags: filter?.tags || [],
        lastChatAtFrom: filter?.updatedAt?.from,
        lastChatAtTo: filter?.updatedAt?.to,
        reverseSort,
        getHiddenCount: filter?.tags?.length > 0 ? true : false,
        performingGlobalSearch: Boolean(filter?.wChannelIds || filter?.tags || filter?.threadMode || filter?.updatedAt?.from || filter?.updatedAt?.to),
      }
      if (filter?.threadMode === "isBot") {
        variable.liveChat = false
      } else if (filter?.threadMode === "isLiveChat") {
        variable.liveChat = true
      }
      startTransition(() => {
        refetch(variable, {
          fetchPolicy: "network-only",
        })
      })
    }
  }, [search, JSON.stringify(filter), reverseSort])

  const updateThreadFolder = ({ folderId, etag, selectedThreadId }) => {
    let clientMutationId = 0
    let variables = {
      threadIds: [selectedThreadId],
      folderId,
      clientMutationId: clientMutationId++,
      //etag,
    }
    const mutationParams = {
      variables: {
        input: variables,
      },
      onCompleted: (response, errs) => {
        if (!errs) {
          setTimeout(() => {
            setTempDeletedThreads((t) => t.filter(o => o !== selectedThreadId))
          }, 0)
        }
      },
      onError: (err) => {
        const errorMsg = err.res?.json?.errors[0]?.message || err
        alert(`Cannot move to ${_.startCase(folderId)} folder by following reason: ${errorMsg}\nPlease try again later.`)
        setTempDeletedThreads((t) => t.filter(o => o !== selectedThreadId))
      }
    }
    commitThreadFolder(mutationParams)
  }

  const totalCount = data?.threads?.pageInfo?.totalCount

  let threads = _.cloneDeep(data?.threads?.edges) || []
  if (tempDeletedThreads?.length) {
    threads = threads.filter((o) => !tempDeletedThreads.includes(o.node.threadId))
  }
  let selectedThreadsCount
  if (selectedThreads?.length) {
    selectedThreadsCount = selectedThreads?.length
  } else if (selectedAllThreads) {
    selectedThreadsCount = totalCount
  } else {
    selectedThreadsCount = "No"
  }

  let disableMoveToButton = true
  if (selectedThreads?.length) {
    disableMoveToButton = false
  }
  if (selectedAllThreads) {
    disableMoveToButton = false
  }

  let selectedThreadText
  const threads_selected = t("threads.threads_selected", { count: selectedThreadsCount })
  const threads_selected_zero = t("threads.threads_selected_zero")

  if (_.isNumber(selectedThreadsCount) && selectedThreadsCount > 1) {
    selectedThreadText = threads_selected
  } else if (_.isNumber(selectedThreadsCount) && selectedThreadsCount === 1) {
    selectedThreadText = threads_selected
  } else {
    selectedThreadText = threads_selected_zero
  }

  const isItemLoaded = (index) => !hasNext || index < threads?.length
  const itemCount = hasNext ? threads.length + 1 : threads.length
  const loadMoreItems = (startIndex, stopIndex) => {
    if (isLoadingNext) return null
    return new Promise(resolve => {
      loadNext(minimumBatchSize, {
        onComplete: (err) => {
          if (!err) {
            resolve()
          }
        }
      })
    })
  }

  if (mobile) {
    return (
      <>
        <Overlay
          show={isMobileGlobalSearchOpen}
          sx={{
            zIndex: 99
          }}
        />
        <MobileStyles.MemberSearchContainer mobile={mobile} show={isMobileGlobalSearchOpen} className="member-search-container">
          <Styles.SearchBarContainer style={{ borderBottom: "none" }}>
            <div className="member-search-container">
              <div style={{ display: "flex", gap: "8px" }}>
                <Styles.MemberSearchBar
                  key="member-search-bar"
                  placeholder={t?.("threads.search")}
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value)
                  }}
                />
                <MobileGlobalSearchCloseWrapper
                  onClick={() => {
                    setIsMobileGlobalSearchOpen(false)
                    setSearch("")
                    setFilter(null)
                    setChannelIdsFilter([])
                  }}
                >
                  <FontAwesomeIcon
                    icon={faX}
                    size="md"
                    className="icon"
                  />
                </MobileGlobalSearchCloseWrapper>
              </div>
              <FontAwesomeIcon
                icon={faSearch}
                className="search-icon"
              />
              <PopperContainer
                className="filter-icon-container"
                modifiers={[
                  {
                    name: 'offset',
                    options: {
                      offset: [5, 10],
                    },
                  },
                ]}
                display={(
                  <NewBoxButton
                    borderless
                    icon={faFilter}
                    sx={{
                      ".icon": {
                        color: _.isEmpty(processedFilter) ? "gray3" : "themeColor",
                      },
                      "&:hover": {
                        ".icon": {
                          color: _.isEmpty(processedFilter) ? "#777779" : "themeColor"
                        }
                      }
                    }}
                    style={{
                      right: "32px",
                    }}
                    onClick={() => {
                      setOpenFilterOptions(true)
                    }}
                  />
                )}
                show={openFilterOptions}
                onClickOutside={() => {
                  setOpenFilterOptions(false)
                }}
                placement="bottom-end"
              >
                {() => (
                  <Box
                    sx={{
                      width: "280px",
                    }}
                  >
                    <Box
                      p={3}
                      sx={{
                        fontSize: 2,
                        color: "gray9",
                        borderBottom: "1px solid",
                        borderColor: "gray1",
                      }}
                    >
                      {t?.("threads.refine_search")}
                    </Box>
                    <Box
                      p={3}
                    >
                      <Box
                        mb={2}
                      >
                        <Text
                          sx={{
                            fontSize: "12px",
                            mb: "2px",
                            display: "inline-block",
                            color: "gray9",
                          }}
                        >
                          {t?.("threads.channels")}
                        </Text>
                        <ChannelsPicker
                          selectedChannelIds={channelIdsFilter}
                          onChange={(ids) => {
                            setChannelIdsFilter(ids)
                          }}
                        />
                      </Box> 
                      <Box
                        mb={2}
                      >
                        <Text
                          sx={{
                            fontSize: "12px",
                            mb: "2px",
                            display: "inline-block",
                            color: "gray9",
                          }}
                        >
                          {t?.("threads.tags")}
                        </Text>
                        <Styles.StyledCreatable
                          options={memberTagsOption}
                          styles={generalStyles}
                          isMulti
                          value={selectedTags}
                          onChange={(tagValue) => {
                            if (tagValue) {
                              setSelectedTags(tagValue)
                            } else {
                              setSelectedTags([])
                            }
                          }}
                        />
                      </Box>
                      <Box>
                        <Text
                          sx={{
                            fontSize: "12px",
                            mb: "2px",
                            display: "inline-block",
                            color: "gray9",
                          }}
                        >
                          {t?.("threads.last_update_time")}
                        </Text>
                        <DateRangePicker
                          mobile
                          defaultFrom={from}
                          defaultTo={to}
                          placement="bottom-start"
                          onRangeChange={({ from, to }) => {
                            setFrom(from)
                            setTo(to)
                          }}
                        />
                      </Box>
                      <Box
                        mt={2}
                      >
                        <Text
                          sx={{
                            fontSize: "12px",
                            mb: "2px",
                            display: "inline-block",
                            color: "gray9",
                          }}
                        >
                          {t?.("threads.chat_mode")}
                        </Text>
                        <Styles.StyledCreatable
                          options={threadModeOptions}
                          styles={generalStyles}
                          value={threadModeOptions.find((threadModeOption) => threadModeOption.value === threadMode)}
                          onChange={(value) => {
                            if (value) {
                              setThreadMode(value?.value)
                            }
                          }}
                        />
                      </Box>

                    </Box>
                    <Flex
                      px={3}
                      py={2}
                      sx={{
                        borderTop: "1px solid",
                        borderColor: "gray1",
                      }}
                    >
                      <NewBoxButton
                        text={t?.("common:buttons.cancel")}
                        sx={{
                          mr: 2,
                          flexGrow: 1,
                        }}
                        onClick={() => {
                          setFrom(null)
                          setTo(null)
                          setChannelIdsFilter([])
                          setSelectedTags([])
                          setThreadMode("all")
                          setOpenFilterOptions(false)
                        }}
                      />
                      <NewBoxButton
                        primary
                        text={t?.("common:buttons.confirm")}
                        sx={{
                          flexGrow: 1,
                        }}
                        onClick={() => {
                          setFilter({
                            wChannelIds: channelIdsFilter?.length ? channelIdsFilter : null,
                            tags: selectedTags?.map((tag) => tag.value),
                            threadMode: threadMode !== "all" ? threadMode : null,
                            updatedAt: from && to ? {
                              from,
                              to,
                            } : null
                          })
                          setOpenFilterOptions(false)
                        }}
                      />
                    </Flex>
                  </Box>
                )}
              </PopperContainer>
              {/* <div
                      className="filter-icon-container"
                      onClick={() => {
                        setOpenMemberSearch(true)
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faFilter}
                        className="filter-icon"   
                      />
                    </div> */}
            </div>
            <ReactTooltip
              id="notification-tooltip"
              place="right"
              style={{
                maxWidth: "300px",
              }}
            />
          </Styles.SearchBarContainer>
          {
           loading
              ? (
                <div style={{ height: "100%", width: "100%", background: "#F0F2F7", paddingTop: "8px", zIndex: 999 }}>
                  <Loader mobile={mobile} />
                  <Loader mobile={mobile} />
                  <Loader mobile={mobile} />
                  <Loader mobile={mobile} />
                  <Loader mobile={mobile} />
                </div>
              )
              : null
          }
          {
            !loading
              ? (
                <>
                  <AutoSizer>
                    {({ height, width }) => (
                      <InfiniteLoader
                        ref={infiniteLoaderRef}
                        isItemLoaded={isItemLoaded}
                        itemCount={itemCount}
                        loadMoreItems={loadMoreItems}
                        minimumBatchSize={minimumBatchSize}
                        threshold={5}
                      >
                        {({ onItemsRendered, ref }) => (
                          <List
                            height={height}
                            itemCount={itemCount}
                            innerElementType={innerElementType}
                            itemSize={mobile ? 100 : 92}
                            itemData={{
                              threads,
                              location,
                              query,
                              mobile,
                              setTempDeletedThreads,
                              updateThreadFolder,
                              threadId,
                              selectedAllThreads,
                              selecting,
                              onChangeThreadId,
                              selectedThreads,
                              setSelectedThreads,
                              isInFlightThreadFolder,
                              hasNext,
                              search,
                              filter,
                              threadMode,
                              from,
                              to,
                              setIsMobileGlobalSearchOpen,
                              setSearch,
                              setFilter,
                              setChannelIdsFilter
                            }}
                            onItemsRendered={onItemsRendered}
                            ref={ref}
                            width={width}
                          >
                            {RowWithTranslatedLastChatAt}
                          </List>
                        )}
                      </InfiniteLoader>
                    )}
                  </AutoSizer>
                  {!threads?.length && (
                    <Styles.NoDataContainer>{t?.("threads.no_messages_now_part_1")}<br /><br />{t?.("threads.no_messages_now_part_2")}</Styles.NoDataContainer>
                  )}
                </>
              )
            : null
          }
        </MobileStyles.MemberSearchContainer>
      </>
    )
  }

  return (
    <>
      <Styles.ThreadsContainer className="threads-container global-threads" selecting={selecting}>
        {loading ?
          <div style={{ height: "440px", width: "100%", background: "#F0F2F7", paddingTop: "8px" }}>
            <Loader mobile={mobile} />
            <Loader mobile={mobile} />
            <Loader mobile={mobile} />
            <Loader mobile={mobile} />
            <Loader mobile={mobile} />
          </div>
          :
          <>
            {showRefetchOverlay &&
              <Styles.ThreadsOverlay className="thread-overlay">
                <div className="overlay-content">
                  <Text className="overlay-text" pb={2} sx={{ fontSize: 1 }}>{t?.("threads.moved_to_another_folder")}</Text>
                  <NewBoxButton
                    className="reload-button"
                    primary
                    text={t?.("threads.reload_button")}
                    icon={faRotateRight}
                    onClick={() => {
                      startTransition(() => {
                        refetch({
                          search,
                          filter,
                          performingGlobalSearch: true,
                        }, {
                          fetchPolicy: "network-only",
                        })
                      })
                      setShowRefetchOverlay(false)
                    }}
                  />
                </div>

              </Styles.ThreadsOverlay>
            }
            {selecting &&
              <Styles.ThreadsManageContainer>
                <Flex sx={{ justifyContent: "center" }}>
                  <Styles.CustomPopperContainer
                    className="custom-popper-container"
                    show={showMoveFolderDropDown}
                    display={(
                      <NewBoxButton
                        color="#405DE6"
                        className="count-select-button"
                        primary
                        disabled={disableMoveToButton || isSuperAuditor || isSuperAdmin}
                        icon={selectedThreadsCount >= 1 && faCheck}
                        text={selectedThreadText}
                        onClick={() => {
                          if (selectedThreads?.length || selectedAllThreads) {
                            setShowMoveFolderDropDown(true)
                          }
                        }}
                      />
                    )}
                    placement="right-start"
                    onClickOutside={() => {
                      if (showMoveFolderDropDown) {
                        setShowMoveFolderDropDown(false)
                      }
                    }}
                  >
                    {() => (
                      <ManageGlobalThreadsPopper
                        setFolderId={setFolderId}
                        search={search}
                        threads={threads}
                        selectedThreadCount={selectedThreads?.length}
                        selectedThreadCountAll={totalCount}
                        selectedAllThreads={selectedAllThreads} 
                        selectedThreads={selectedThreads}
                        currentFolder={folderId}
                        currentChannelIds={channelIds}
                        role={data?.role}
                        filter={filter}
                        threadMode={threadMode}
                        from={from}
                        to={to}
                        folderId={selectedFolder}
                        selectedFolder={selectedFolder}
                        setSelectedFolder={setSelectedFolder}
                        setSearch={setSearch}
                        setFilter={setFilter}
                        setChannelIdsFilter={setChannelIdsFilter}
                        setShowRefetchOverlay={setShowRefetchOverlay}
                        onSuccess={() => {
                          setShowMoveFolderDropDown(false)
                          onFinishUpdateThreadsFolder(true)
                          setSelectedThreads([])
                          setSelectedAllThreads(false)
                        }}
                      />
                    )}
                  </Styles.CustomPopperContainer>
                </Flex>
                {data?.role === "ADMIN" || data?.role === "SUPER_ADMIN" ? (
                  <Flex sx={{ justifyContent: "center" }}>
                    <NewBoxButton
                      className="select-all-button"
                      borderless
                      primary
                      disabled={totalCount <= 0}
                      text={selectedAllThreads ? `Unselect all ${totalCount} threads` : `Select all ${totalCount} threads`}
                      onClick={() => {
                        setSelectedAllThreads(!selectedAllThreads)
                        setSelectedThreads([])
                      }}
                    />
                  </Flex>
                ) : null}
              </Styles.ThreadsManageContainer>
            }
            <AutoSizer>
              {({ height, width }) => (
                <InfiniteLoader
                  ref={infiniteLoaderRef}
                  isItemLoaded={isItemLoaded}
                  itemCount={itemCount}
                  loadMoreItems={loadMoreItems}
                  minimumBatchSize={minimumBatchSize}
                  threshold={5}
                >
                  {({ onItemsRendered, ref }) => (
                    <List
                      height={height}
                      itemCount={itemCount}
                      innerElementType={innerElementType}
                      itemSize={mobile ? 100 : 92}
                      itemData={{
                        threads,
                        location,
                        query,
                        mobile,
                        setTempDeletedThreads,
                        updateThreadFolder,
                        threadId,
                        selectedAllThreads,
                        selecting,
                        onChangeThreadId,
                        selectedThreads,
                        setSelectedThreads,
                        isInFlightThreadFolder,
                        hasNext,
                        search,
                        filter,
                        threadMode,
                        from,
                        to,
                      }}
                      onItemsRendered={onItemsRendered}
                      ref={ref}
                      width={width}
                    >
                      {RowWithTranslatedLastChatAt}
                    </List>
                  )}
                </InfiniteLoader>
              )}
            </AutoSizer>
            {!threads?.length && (
              <Styles.NoDataContainer>{t?.("threads.no_messages_now_part_1")}<br /><br />{t?.("threads.no_messages_now_part_2")}</Styles.NoDataContainer>
            )}
          </>
        }
        <ReactTooltip
          id="thread-option-tooltip"
          place="right"
        />
      </Styles.ThreadsContainer>
    </>
  )
}

function createLiveChatInputForQuery(threadMode) {
  if (threadMode === "isBot") {
    return {
      liveChat: false,
    }
  }
  if (threadMode === "isLiveChat") {
    return {
      liveChat: true,
    }
  }
  return {}
}

const ManageGlobalThreadsPopper = ({
  setFolderId,
  selectedThreadCount,
  selectedThreadCountAll,
  selectedAllThreads,
  onSuccess,
  search,
  selectedThreads: selectedThreadsIds,
  filter,
  threadMode,
  from,
  to,
  folderId,
  selectedFolder,
  setSelectedFolder,
  setSearch,
  setFilter,
  setChannelIdsFilter,
}) => {
  const [error, setError] = useState(null)
  const [isConflictModalOpen, setIsConflictModalOpen] = useState(false)
  const [conflictData, setConflictData] = useState(null)
  const [destinationFolderName, setDestinationFolderName] = useState(null)
  const {
    setBackgroundTaskId,
    backgroundTaskId: runningBackgroundTaskId,
    setIsBackgroundTaskPending,
  } = useContext(BackgroundTaskContext)
  const [commitThreadsFolder, isInFlightThreadsFolder] = useMutation(
    ThreadsUpdateThreadsFolderMutation
  )

  const { t } = useTranslation("common")

  const resetGlobalThreadSearch = () => {
    setError(null)
    onSuccess()
    setSearch("")
    setFilter(null)
    setChannelIdsFilter([])
  }

  const updateThreadsFolder = ({
    variables,
  }) => {
    let clientMutationId = 0

    let finalVariables = {
      clientMutationId: clientMutationId++,
      ...variables
    }
    const mutationParams = {
      variables: {
        input: {
          ...finalVariables
        },
      },
      onCompleted: (response, _errs) => {
        const isError = response?.updateThreadsFolder.ok !== 1
        const error = response?.updateThreadsFolder?.error?.message
        const hasConflicts = response?.updateThreadsFolder?.threadsConflictResult?.conflicts?.length > 0

        const destinationFolderName = response?.updateThreadsFolder?.destinationFolder?.name ?? null
        const destinationFolderId = response?.updateThreadsFolder?.destinationFolder?.folderId ?? null
        const threadsMovedCount = response?.updateThreadsFolder?.threads?.length

        const isBackgroundTask = Boolean(response?.updateThreadsFolder?.backgroundTaskId)
        const backgroundTaskId = response?.updateThreadsFolder?.backgroundTaskId

        if (!error) {
          setError(null)
        }

        if (isError || error) {
          setError(error)
          return
        }

        if (isBackgroundTask) {
          if (runningBackgroundTaskId) {
            alert("You can run one moving threads action at a time in the background. Please wait until the previous action is done.")
            return
          }
          setIsBackgroundTaskPending(true)
          setBackgroundTaskId(backgroundTaskId);
          resetGlobalThreadSearch()
          return;
        }

        if (hasConflicts) {
          setConflictData(response.updateThreadsFolder.threadsConflictResult)
          setIsConflictModalOpen(true)
          setDestinationFolderName(destinationFolderName)
          return
        }

        toast.custom(
          <ThreadMoveSuccessToast setFolderId={setFolderId} threadsCount={threadsMovedCount} destinationFolderId={destinationFolderId} destinationFolderName={destinationFolderName} />, {
            duration: 8000
          }
        )
        resetGlobalThreadSearch()
      },
      onError: (err) => {
        console.log(err)
        const errorMsg = err.res?.json?.errors[0]?.message || err
        alert(`Cannot move to ${_.startCase(folderId)} folder by following reason: ${errorMsg}\nPlease try again later.`)
      }
    }
    commitThreadsFolder(mutationParams)
  }

  return (
    <>
      <Styles.MoveFolderContainer
        showError={error}
      >
        <div className="label">
          {
            selectedAllThreads
              ? t?.("threads.threads_ready_to_move", { count: selectedThreadCountAll })
              : t?.("threads.threads_ready_to_move", { count: selectedThreadCount })
          }
        </div>
        <div className="separator"></div>
        <Box p={3}>
          <FolderPicker
            onChange={(folderId) => {
              setSelectedFolder(folderId)
            }}
            selectedFolderId={selectedFolder}
          />
          <NewBoxButton
            style={{ marginTop: "8px", width: "100%" }}
            primary
            text={t?.("buttons.confirm")}
            disabled={!selectedFolder || isInFlightThreadsFolder}
            loading={isInFlightThreadsFolder}
            onClick={() => {
              updateThreadsFolder({
                  variables: constructUpdateThreadMutationVariables({
                  filter,
                  search,
                  threadMode,
                  from,
                  to,
                  folderId,
                  selectedThreadsIds,
                })
              })
            }}
          />
          {error ?
            <div className="error">{error}</div>
            :
            null
          }
        </Box>
      </Styles.MoveFolderContainer>
      <MovingThreadsConflictModal
        isOpen={isConflictModalOpen && conflictData}
        onClose={() => {
          startTransition(() => {
            setIsConflictModalOpen(false)
            onSuccess()
            setSearch("")
          })
        }}
        setFolderId={setFolderId}
        conflictData={conflictData}
        destinationFolderName={destinationFolderName}
        threadIds={selectedThreadsIds}
        folderId={selectedFolder}
        threadsFilter={{
          ...createLiveChatInputForQuery(threadMode),
          search: search,
          tags: filter?.tags ?? null,
          lastChatAtFrom: from ?? null,
          lastChatAtTo: to ?? null,
          wChannelIds: filter?.wChannelIds ?? null,
        }}
      />
    </>
  )
}
export default GlobalThreads
