import React, { startTransition, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import { Box } from "theme-ui"
import {
  useMutation,
} from "react-relay"
import { toast } from "react-hot-toast"

import { ThreadsUpdateThreadsFolderMutation } from "./ManageThreadsPopperQuery"
import FolderPicker from "../FolderPicker/FolderPicker"
import NewBoxButton from "../../components/Radiate/NewBoxButton/NewBoxButton"
import * as Styles from "./ManageThreadsPopperStyles.js"
import MovingThreadsConflictModal from "../../components/MoveThreads//MovingThreadsConflictModal.js"
import { ThreadMoveSuccessToast } from "../MoveThreads/MoveThreads"
import { BackgroundTaskContext } from "../../contexts/BackgroundTaskContext "


const ManageThreadsPopper = ({
  selectedThreadCount,
  selectedThreadCountAll,
  selectedAllThreads,
  selectedThreads,
  currentFolder,
  currentChannelIds,
  onSuccess,
  search,
  threads,
  setFolderId,
  setSearch,
  setFilter,
  setChannelIdsFilter
}) => {
  const [error, setError] = useState(null)
  const [isConflictModalOpen, setIsConflictModalOpen] = useState(false)
  const [conflictData, setConflictData] = useState(null)
  const [destinationFolderName, setDestinationFolderName] = useState(null)
  const [selectedFolder, setSelectedFolder] = useState(null)
  const { setBackgroundTaskId, backgroundTaskId: runningBackgroundTaskId, setIsBackgroundTaskPending } = useContext(BackgroundTaskContext)
  const [commitThreadsFolder, isInFlightThreadsFolder] = useMutation(
    ThreadsUpdateThreadsFolderMutation
  )
  const { t } = useTranslation("common")

  const resetThreadSearch = () => {
    setError(null)
    onSuccess()
    setSearch("")
    setFilter(null)
    setChannelIdsFilter([])
  }

  const updateThreadsFolder = ({
    targetFolderId,
    selectedThreadIds,
    selectedAllThreads,
    userOption,
    search,
    threads
  }) => {
    let clientMutationId = 0
    let variables = {
      folderId: targetFolderId,
      clientMutationId: clientMutationId++,
      returnConflictsGroupedByChannel: true,
    }

    if (!targetFolderId) throw new Error("destination folder ID is required")

    if (selectedAllThreads) {
      if (search) {
        const threadIds = threads.map((thread) => thread?.node?.threadId)
        variables.threadIds = threadIds
      } else {
        variables.originFolderId = currentFolder
        variables.wChannelIds = currentChannelIds ? currentChannelIds : null
      }
    } else {
      variables.threadIds = selectedThreadIds
    }
    if (userOption) {
      variables.userOption = userOption
    }
    const mutationParams = {
      variables: {
        input: variables,
      },
      onCompleted: (response) => {
        const isError = response?.updateThreadsFolder.ok !== 1
        const error = response?.updateThreadsFolder?.error?.message

        const hasConflicts = response?.updateThreadsFolder?.threadsConflictResult?.conflicts?.length > 0

        const destinationFolderName = response?.updateThreadsFolder?.destinationFolder?.name ?? null
        const destinationFolderId = response?.updateThreadsFolder?.destinationFolder?.folderId ?? null
        const threadsMovedCount = response?.updateThreadsFolder?.threads?.length

        const isBackgroundTask = Boolean(response?.updateThreadsFolder?.backgroundTaskId)
        const backgroundTaskId = response?.updateThreadsFolder?.backgroundTaskId

        if (!error) {
          setError(null)
        }

        if (isError || error) {
          setError(error)
          return
        }

        if (isBackgroundTask) {
          if (runningBackgroundTaskId) {
            alert("You can run one moving threads action at a time in the background. Please wait until the previous action is done.")
            return
          }
          setIsBackgroundTaskPending(true)
          setBackgroundTaskId(backgroundTaskId)
          resetThreadSearch()
          return
        }

        if (hasConflicts) {
          setConflictData(response.updateThreadsFolder.threadsConflictResult)
          setIsConflictModalOpen(true)
          setDestinationFolderName(destinationFolderName)
          return
        }

        toast.custom(
          <ThreadMoveSuccessToast setFolderId={setFolderId} threadsCount={threadsMovedCount} destinationFolderId={destinationFolderId} destinationFolderName={destinationFolderName} />, {
            duration: 8000
          }
        )
        resetThreadSearch()
      },
      onError: (err) => {
        const errorMsg = err.res?.json?.errors[0]?.message || err
        alert(`Cannot move to ${_.startCase(targetFolderId)} folder by following reason: ${errorMsg}\nPlease try again later.`)
      }
    }
    commitThreadsFolder(mutationParams)
  }

  return (
    <>
      <Styles.MoveFolderContainer
        showError={error}
      >
        <div className="label">
          {
            selectedAllThreads
              ? t?.("threads.threads_ready_to_move", { count: selectedThreadCountAll })
              : t?.("threads.threads_ready_to_move", { count: selectedThreadCount })
          }
        </div>
        <div className="separator"></div>
        <Box p={3}>
          <FolderPicker
            onChange={(folderId) => {
              setSelectedFolder(folderId)
            }}
            selectedFolderId={selectedFolder}
          />
          <NewBoxButton
            style={{ marginTop: "8px", width: "100%" }}
            primary
            text={t?.("buttons.confirm")}
            disabled={!selectedFolder || isInFlightThreadsFolder}
            loading={isInFlightThreadsFolder}
            onClick={() => {
              updateThreadsFolder({ targetFolderId: selectedFolder, selectedThreadIds: selectedThreads, selectedAllThreads, search, threads })
            }}
          />
          {error ?
            <div className="error">{error}</div>
            :
            null
          }
        </Box>
      </Styles.MoveFolderContainer>
      <MovingThreadsConflictModal
        isOpen={isConflictModalOpen && conflictData}
        onClose={() => {
          startTransition(() => {
            setIsConflictModalOpen(false)
            onSuccess()
          })
        }}
        setFolderId={setFolderId}
        conflictData={conflictData}
        destinationFolderName={destinationFolderName}
        threadIds={selectedThreads}
        originFolderId={currentFolder}
        folderId={selectedFolder}
        wChannelIds={currentChannelIds ? currentChannelIds : null}
        selectedAllThreads={selectedAllThreads}
      />
    </>
  )
}

export default ManageThreadsPopper
