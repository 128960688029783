import React, { useMemo, useRef, useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { useSubscription } from "react-relay";
import {
  ThreadMoveConflictToast,
  ThreadMoveErrorToast,
  ThreadMoveLoadingToast,
  ThreadMoveSuccessToast,
} from "../components/MoveThreads/MoveThreads";
import MovingThreadsConflictModal from "../components/MoveThreads/MovingThreadsConflictModal";
import { BackgroundTaskSubscription } from "../pages/Inbox/InboxQuery";

export const BackgroundTaskContext = React.createContext({
  setBackgroundTaskId: () => {},
  backgroundTaskId: null,
  setIsBackgroundTaskPending: () => {},
  resetBackgroundTask: () => {},
});

const BackgroundTaskProvider = ({ setFolderId = () => {}, children }) => {
  const [isConflictModalOpen, setIsConflictModalOpen] = useState(false);
  const [backgroundTaskId, setBackgroundTaskId] = useState(null);
  const [conflictData, setConflictData] = useState(null);
  const [destinationFolderName, setDestinationFolderName] = useState(null);
  const toastIdRef = useRef(null);
  const [isBackgroundTaskPending, setIsBackgroundTaskPending] = useState(false);

  const loadingToastId = useRef(null)

  const resetBackgroundTask = () => {
    setBackgroundTaskId(null);
    setIsBackgroundTaskPending(false);
  };

  const backgroundTaskSubscriptionConfig = useMemo(() => {
    if (backgroundTaskId) {
      return {
        variables: { backgroundTaskId },
        subscription: BackgroundTaskSubscription,
        onNext: (data) => {
          const STATUSES = {
            // NOTE: succcess is just the status of the backgorund result; this may result in a conflict
            SUCCESS: "SUCCESS",
            ERROR: "ERROR",
          };

          const bgTask = data?.backgroundTask?.backgroundTask;
          const bgStatus = bgTask?.status;
          const bgUpdateThreadsResult = data?.backgroundTask?.updateThreads;

          const isError = bgUpdateThreadsResult.ok !== 1;
          const error = bgUpdateThreadsResult?.error?.message;
          const hasConflicts =
            bgUpdateThreadsResult?.threadsConflictResult?.conflicts?.length > 0;
          const conflictResult = bgUpdateThreadsResult?.threadsConflictResult;
          const destinationFolderName =
            bgUpdateThreadsResult?.destinationFolder?.name ?? null;
          const destinationFolderId =
            bgUpdateThreadsResult?.destinationFolder?.folderId ?? null;
          const threadsMovedCount = bgUpdateThreadsResult?.threads?.length;

          if (bgStatus === STATUSES.ERROR || isError || error) {
            toast.remove()
            toast.custom(<ThreadMoveErrorToast message={error} />, {
              duration: 8000,
            });
            resetBackgroundTask();
            return;
          }

          if (bgStatus === STATUSES.SUCCESS) {
            if (hasConflicts) {
              setConflictData(conflictResult);
              setDestinationFolderName(destinationFolderName);
              toast.remove()
              toastIdRef.current = toast.custom(
                <ThreadMoveConflictToast
                  onClick={() => setIsConflictModalOpen(true)}
                />,
                {
                  duration: Infinity,
                },
              );
            } else {
              toast.custom(
                <ThreadMoveSuccessToast
                  setFolderId={setFolderId}
                  threadsCount={threadsMovedCount}
                  destinationFolderId={destinationFolderId}
                  destinationFolderName={destinationFolderName}
                />,
                {
                  duration: 8000,
                },
              );
              resetBackgroundTask();
            }
            return;
          }
        },
        onError: (e) => {
          console.error("subscription error", e);
          toast.custom(<ThreadMoveErrorToast message={e?.message} />, {
            duration: 8000,
          });
          resetBackgroundTask();
          return;
        },
      };
    }
    return {
      variables: { backgroundTaskId: "" },
      subscription: BackgroundTaskSubscription,
    };
  }, [backgroundTaskId]);

  useSubscription(backgroundTaskSubscriptionConfig);

  useEffect(() => {
    if (isBackgroundTaskPending) {
      loadingToastId.current = toast.custom(<ThreadMoveLoadingToast />, {
        duration: Infinity,
      });
    } else {
      toast.remove(loadingToastId.current)
    }
  }, [isBackgroundTaskPending, backgroundTaskId])

  return (
    <BackgroundTaskContext.Provider
      value={{
        setBackgroundTaskId,
        backgroundTaskId,
        setIsBackgroundTaskPending,
        resetBackgroundTask,
      }}
    >
      {children}
      <MovingThreadsConflictModal
        isOpen={isConflictModalOpen}
        onClose={() => setIsConflictModalOpen(false)}
        conflictData={conflictData}
        destinationFolderName={destinationFolderName}
        setFolderId={setFolderId}
        backgroundTaskId={backgroundTaskId}
        resetToasts={() => {
          toast.remove();
        }}
      />
    </BackgroundTaskContext.Provider>
  );
};

export default BackgroundTaskProvider;
