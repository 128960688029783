import React, { useEffect, useState } from "react"
import graphql from "babel-plugin-relay/macro"
import { useRefetchableFragment } from "react-relay"
import { loadQuery, usePreloadedQuery } from "react-relay"
const UserContextQuery = graphql`
  query UserContextQuery {
    userViewer {
      ...UserContext_userViewer
    }
  }
`

const UserContextProvider = ({
  environment,
  children,
  ...props
}) => {
  const [loadedQuery, setLoadedQuery] = useState(null)
  useEffect(() => {
    if (navigator.userAgent !== "ReactSnap") {
      setLoadedQuery(loadQuery(environment, UserContextQuery, {}))
    }
  }, [])

  useEffect(() => {
    if (loadedQuery) {
      loadedQuery.dispose()
    }
  }, [JSON.stringify(loadedQuery)])

  if (navigator.userAgent === "ReactSnap") {
    return (
      <UserContext.Provider
        value={{
          role: "ADMIN",
          isSuperAdmin: true,
          isSuperAuditor: false,
        }}
      >
        {children}
      </UserContext.Provider>
    )
  }
  if (loadedQuery) {
    return (
      <InnerUserContextProvider
        queryRef={loadedQuery}
        children={children}
        {...props}
      />
    )
  }
  return null
}

export const UserContext = React.createContext({
  role: null,
  isSuperAdmin: false,
  isSuperAuditor: false,
  isAdmin: false,
})

const InnerUserContextProvider = ({
  children,
  ...props
}) => {
  const result = usePreloadedQuery(
    UserContextQuery,
    props.queryRef
  )
  const [
    data
  ] = useRefetchableFragment(
    graphql`
      fragment UserContext_userViewer on UserScope
      @refetchable(queryName: "UserContextRefetchQuery") {
        role
      }
    `,
    result.userViewer
  )
  const isSuperAdmin = data?.role === "SUPER_ADMIN"
  const isSuperAuditor = data?.role === "SUPER_AUDITOR"
  const isAdmin = data?.role === "ADMIN"

  return (
    <UserContext.Provider
      value={{
        role: data?.role,
        isSuperAdmin,
        isSuperAuditor,
        isAdmin,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserContextProvider
