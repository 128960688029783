/** @jsxImportSource theme-ui */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPlus,
  faCog,
  faStar,
  faLock,
  faUserTag,
  faInbox,
  faCheck,
  faBan,
  faFolderTree,
} from "@fortawesome/free-solid-svg-icons"
import qs from "query-string"

import {
  useRefetchableFragment,
} from "react-relay"

import _ from "lodash"
import React, { useState, useEffect, useTransition, useRef, useContext } from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useNavigation } from "yarr"
import graphql from "babel-plugin-relay/macro"
import { Flex } from "theme-ui"
import ContentLoader from "react-content-loader"

import ReactTooltip from "../../components/ReactTooltip/ReactTooltip"
import IntegrationActionPopperContainer from "../../components/IntegrationActionPopperContainer/IntegrationActionPopperContainer"
import Modal from "../../components/Radiate/Modal/Modal"
import SettingsModal from "../../components/SettingsModal/SettingsModal"
import { UserContext } from "../../contexts/UserContext"
import * as Styles from "./InboxStyles"

import { FOLLOW_UP_COLOR, IDLE_TIME_MS, FOLLOW_UP_TEMP_FOLDER_ID } from "../../../src/const"
import FolderInfoPopper from "../../components/FolderInfoPopper/FolderInfoPopper"
import { WoztellContext } from "../../contexts/WoztellContextProvider"
import iconUnreadMessage from "../../public/img/icon-unread-message.svg"

const longPoller = new Worker(new URL("../../webWorkers/longPolling.js", import.meta.url))

const Loader = (props) => (
  <ContentLoader
    speed={2}
    width={64}
    height={52}
    viewBox="0 0 64 52"
    backgroundColor="#e6e6e6"
    foregroundColor="#f0f0f0"
    {...props}
  >
    <rect x="13" y="12" rx="8" ry="8" width="38" height="38" />
  </ContentLoader>
)

const MobileLoader = (props) => (
  <ContentLoader
    speed={2}
    width={68}
    height={60}
    viewBox="0 0 68 60"
    backgroundColor="#5b86ff"
    foregroundColor="#84a3fb"
    {...props}
    sx={{
      flexShrink: 0
    }}
  >
    <rect x="9" y="5" rx="8" ry="8" width="50" height="50" />
  </ContentLoader>
)

const addDisplayIcon = (folder) => {
  if (!folder?.folderId) {
    return
  }
  const iconObject = {
    main: faInbox,
    done: faCheck,
    spam: faBan,
  }
  return {
    ...folder,
    displayIcon: iconObject[folder.folderId] ?? null
  }
}

const Folders = ({
  isInBackground,
  selectedFolder,
  onChangeMemberSearch,
  onChangeSelectedFolder,
  wChannelIds,
  topBarProps = {},
  mobile = false,
  standalone = false,
  ...props
}) => {
  const { isSuperAdmin, isSuperAuditor } = useContext(UserContext)
  const { t } = useTranslation("common")
  const { payload, signedContext } = useContext(WoztellContext)
  const idleTimer = useRef(null)
  const { location } = useHistory()
  const navigation = useNavigation()
  const query = qs.parse(location.search)
  const [showDropDown, setShowDropDown] = useState(false)
  const [selectedFolderName, setSelectedFolderName] = useState(t?.("folders.main"))
  const [openSettingsModal, setOpenSettingsModal] = useState(query?.settings)
  // eslint-disable-next-line no-unused-vars
  const [loading, startTransition] = useTransition()
  const [folders, setFolders] = useState([])
  
  const [
    data,
    refetch
  ] = useRefetchableFragment(
    graphql`
      fragment Folders_userViewer on UserScope
      @argumentDefinitions(
        wChannelIds: { type: "[ID]" }
      )
      @refetchable(queryName: "FoldersRefetchQuery") {
        folders (wChannelIds: $wChannelIds) {
          folderId
          name
          color
          isPrivate
          userIds
          unreadThreadCount
        }
        role
      }
    `,
    props.data.userViewer
  )

  useEffect(() => {
    setFolders(data?.folders)
  }, [JSON.stringify(data?.folders)])

  useEffect(() => {
    if (!isInBackground) {
      refetch({
        wChannelIds: wChannelIds?.length ? wChannelIds : null,
      }, {
        fetchPolicy: "network-only",
      })
    }
  }, [JSON.stringify(wChannelIds), isInBackground])


  useEffect(() => {
    longPoller.postMessage({
      payload,
      signedContext,
      wChannelIds,
    })
    longPoller.onmessage = (e) => {
      const folders = e.data?.userViewer?.folders ?? []
      setFolders(folders)
    }
    longPoller.onerror = (e) => {
      console.log("long polling error", e)
    }
    return () => {
      longPoller.terminate()
    }
  }, [])

  useEffect(() => {
    longPoller.postMessage({
      wChannelIds: wChannelIds || null,
    })
  }, [JSON.stringify(wChannelIds)])

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        longPoller.postMessage({
          action: "PAUSE"
        })
      } else {
        longPoller.postMessage({
          action: "RESUME"
        })
      }
    }

    const handleIdleTimeout = () => {
      // if (!document.hasFocus()) {
      longPoller.postMessage({
        action: "PAUSE"
      })
      // }
    }

    const handleIdleEvent = (event) => {
      if (event instanceof MouseEvent && event.movementX === 0 && event.movementY === 0) {
        return
      }
      if (idleTimer.current) {
        clearTimeout(idleTimer.current)
      }
      longPoller.postMessage({
        action: "RESUME"
      })
      idleTimer.current = setTimeout(handleIdleTimeout, IDLE_TIME_MS)
    }

    idleTimer.current = setTimeout(handleIdleTimeout, IDLE_TIME_MS)
    document.addEventListener("visibilitychange", handleVisibilityChange)
    document.addEventListener("mousemove", handleIdleEvent)
    document.addEventListener("mousedown", handleIdleEvent)
    document.addEventListener("keyup", handleIdleEvent)
    document.addEventListener("touchstart", handleIdleEvent)
    document.addEventListener("scroll", handleIdleEvent)
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange)
      document.removeEventListener("mousemove", handleIdleEvent)
      document.removeEventListener("mousedown", handleIdleEvent)
      document.removeEventListener("keyup", handleIdleEvent)
      document.removeEventListener("touchstart", handleIdleEvent)
      document.removeEventListener("scroll", handleIdleEvent)
      if (idleTimer.current) {
        clearTimeout(idleTimer.current)
      }
    }
  }, [])

  const otherFolders = (folders || []).filter(f => f.folderId !== "main" && f.folderId !== "done" && f.folderId !== "spam")
  const main = addDisplayIcon((folders || []).find(o => o.folderId === "main"))
  const done = addDisplayIcon((folders || []).find(o => o.folderId === "done"))
  const spam = addDisplayIcon((folders || []).find(o => o.folderId === "spam"))
  
  const newFoldersList = _.compact([main, done, spam, ...otherFolders])
  const role = data?.role

  useEffect(() => {
    let selected = folders?.find(folder => folder.folderId === selectedFolder)
    setSelectedFolderName(selected === undefined ? t?.("folders.follow_up") : selected.name)
  }, [selectedFolder, folders])

  function passFolderNameThroughTranslation(folder) {
    const id = folder?.folderId
    if (id === "main") {
      return t?.("folders.main")
    }
    if (id === "done") {
      return t?.("folders.done")
    }
    if (id === "spam") {
      return t?.("folders.spam")
    }
    return folder?.name
  }

  return (
    <>
      <Styles.MobileUIInbox>
        <Styles.MobileInboxHeader screenType="inbox">
          <Styles.FolderContainerMobile>
            {(loading || !folders?.length) ?
              <>
                <MobileLoader />
                <MobileLoader />
                <MobileLoader />
                <MobileLoader />
              </>
              :
              <>
                <Styles.FolderBoxMobile
                  color={FOLLOW_UP_COLOR}
                  selected={selectedFolder === FOLLOW_UP_TEMP_FOLDER_ID}
                  onClick={() => {
                    onChangeSelectedFolder(FOLLOW_UP_TEMP_FOLDER_ID)
                  }}
                >
                  <Styles.FolderMobile
                    color={FOLLOW_UP_COLOR}
                    selected={selectedFolder === FOLLOW_UP_TEMP_FOLDER_ID}
                  >
                    <FontAwesomeIcon
                      icon={faStar}
                    />
                  </Styles.FolderMobile>
                </Styles.FolderBoxMobile>
                {(newFoldersList || []).map((folder) => {
                  return (
                    <Styles.FolderBoxMobile
                      key={folder?.folderId}
                      color={folder?.color}
                      selected={selectedFolder === folder?.folderId}
                      onClick={() => {
                        onChangeSelectedFolder(folder?.folderId)
                      }}
                    >
                      <Styles.FolderMobile
                        color={folder?.color}
                        selected={selectedFolder === folder?.folderId}
                      >
                        {folder?.displayIcon ? (
                          <FontAwesomeIcon
                            icon={folder?.displayIcon}
                          />
                        ) : (
                          <>
                            {[...folder?.name][0]}
                          </>
                        )}
                        
                        {(folder?.unreadThreadCount ?? 0) > 0 ?
                          <Styles.Badge
                            mobile
                          >
                            {folder.unreadThreadCount > 99 ? "99+" : folder.unreadThreadCount}
                          </Styles.Badge>
                          :
                          null
                        }
                        {folder?.isPrivate && (
                          <Styles.PrivateBadge
                            className="private-badge"
                            mobile
                          >
                            <FontAwesomeIcon
                              icon={faLock}
                            />
                          </Styles.PrivateBadge>
                        )}
                      </Styles.FolderMobile>
                    </Styles.FolderBoxMobile>
                  )
                })}
              </>
            }
          </Styles.FolderContainerMobile>
          <Flex
            sx={{
              px: 3,
              py: 2,
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <div
              sx={{
                color: "white",
                fontSize: 22,
                fontWeight: "bold",
              }}
            >{selectedFolderName}</div>
            <Flex
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {!!topBarProps?.folderActions?.length &&
                <IntegrationActionPopperContainer
                  disable={isSuperAdmin || isSuperAuditor}
                  actions={topBarProps?.folderActions}
                  useInThread
                  onChangeNavHint={topBarProps?.setNavHint}
                  mobile
                />
              }
              <Styles.IconButton
                mobile
                className="filter-unread-button"
                text={(
                  <div
                    className="svg-container"
                  >
                    <img
                      src={iconUnreadMessage}
                      alt="Filter Unread Threads"
                      className="svg-icon"
                    />
                  </div>
                )}
                borderless
                inverted
                size="L"
                data-tooltip-content={t?.("tooltips.filter_unread_threads")}
                data-tooltip-id="inbox-tooltip"
                $on={topBarProps?.isFilteringUnread}
                onClick={() => {
                  topBarProps?.setIsFilteringUnread(!topBarProps?.isFilteringUnread)
                }}
              />
              {topBarProps?.folderId !== FOLLOW_UP_TEMP_FOLDER_ID && !mobile &&
                <Styles.IconButton
                  className="multi-select-button"
                  icon={faFolderTree}
                  borderless
                  inverted
                  size="L"
                  data-tooltip-content={t?.("tooltips.manage_threads")}
                  data-tooltip-id="inbox-tooltip"
                  $on={topBarProps?.selectingThreads}
                  onClick={() => {
                    topBarProps?.setSelectingThreads(!topBarProps?.selectingThreads)
                  }}
                />
              }
            </Flex>
          </Flex>
        </Styles.MobileInboxHeader>
      </Styles.MobileUIInbox>
      <Styles.DesktopUIInbox>
        <Styles.SideBarContainer>
          <Styles.FolderContainer>
            {(loading || !folders?.length) ?
              <>
                <Loader />
                <Loader />
                <Loader />
                <Loader />
              </>
              :
              <>
                <Styles.FolderBox
                  color={FOLLOW_UP_COLOR}
                  data-tooltip-content={t?.("folders.follow_up")}
                  data-tooltip-id="folder"
                  selected={selectedFolder === FOLLOW_UP_TEMP_FOLDER_ID}
                  onClick={() => {
                    onChangeSelectedFolder(FOLLOW_UP_TEMP_FOLDER_ID)
                  }}
                >
                  <Styles.Folder
                    color={FOLLOW_UP_COLOR}
                    selected={selectedFolder === FOLLOW_UP_TEMP_FOLDER_ID}
                  >
                    <FontAwesomeIcon
                      icon={faUserTag}
                    />
                  </Styles.Folder>
                </Styles.FolderBox>
                {(newFoldersList || []).map((folder) => {
                  return (
                    <Styles.FolderBox
                      key={folder?.folderId}
                      color={folder?.color}
                      data-tooltip-content={`${passFolderNameThroughTranslation(folder)}${folder?.unreadThreadCount > 0 ? ` (${folder.unreadThreadCount})` : ""}`}
                      data-tooltip-id="folder"
                      selected={selectedFolder === folder?.folderId}
                      onClick={() => {
                        onChangeSelectedFolder(folder?.folderId)
                      }}
                    >
                      <Styles.Folder
                        color={folder?.color}
                        selected={selectedFolder === folder?.folderId}
                      > 
                        {folder?.displayIcon ? (
                          <FontAwesomeIcon
                            icon={folder?.displayIcon}
                          />
                        ) : (
                          <>
                            {[...folder?.name][0]}
                          </>
                        )}
                        {(folder?.unreadThreadCount ?? 0) > 0 ?
                          <Styles.Badge>
                            {folder.unreadThreadCount > 99 ? "99+" : folder.unreadThreadCount}
                          </Styles.Badge>
                          :
                          null
                        }
                        {folder?.isPrivate && (
                          <Styles.PrivateBadge className="private-badge">
                            <FontAwesomeIcon
                              icon={faLock}
                            />
                          </Styles.PrivateBadge>
                        )}
                      </Styles.Folder>
                    </Styles.FolderBox>
                  )
                })}
                {role === "ADMIN" || role === "SUPER_ADMIN" ? (
                  <Styles.AddFolderPopperContainer
                    show={showDropDown}
                    display={(
                      <Styles.AddFolderButton
                        onClick={() => {
                          setShowDropDown(!showDropDown)
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faPlus}
                        />
                      </Styles.AddFolderButton>
                    )}
                    placement="right"
                    modifiers={[
                      {
                        name: "offset",
                        options: {
                          offset: [0, 8],
                        },
                      },
                      {
                        name: "preventOverflow",
                        options: {
                          padding: 72,
                        },
                      },
                    ]}
                    onClickOutside={() => {
                      if (showDropDown) {
                        setShowDropDown(false)
                      }
                    }}
                  >
                    {() => (
                      <FolderInfoPopper
                        action="CREATE"
                        wChannelIds={wChannelIds}
                        onSuccess={() => {
                          refetch({
                            wChannelIds: wChannelIds?.length ? wChannelIds : null,
                          }, {
                            fetchPolicy: "network-only",
                          })
                          setShowDropDown(false)
                        }}
                      />
                    )}
                  </Styles.AddFolderPopperContainer>
                ) : null}
              </>
            }
          </Styles.FolderContainer>
          <Styles.StyledLink
            to={{
              ...location,
              search: qs.stringify({
                ...query ?? {},
                settings: 1
              })
            }}
          >
            <Styles.SettingsButton
              onClick={() => {
                setOpenSettingsModal(true)
              }}
            >
              <FontAwesomeIcon
                icon={faCog}
              />
            </Styles.SettingsButton>
          </Styles.StyledLink>
          <Modal
            open={openSettingsModal}
          >
            {({ modalRef }) => (
              <SettingsModal
                ref={modalRef}
                standalone={standalone}
                onClose={() => {
                  setOpenSettingsModal(false)
                  navigation.replace({
                    ...location,
                    search: qs.stringify(_.omit(query ?? {}, ["settings"]))
                  })
                }}
              />
            )}
          </Modal>
        </Styles.SideBarContainer>
      </Styles.DesktopUIInbox>
      <ReactTooltip
        id="folder"
        place="right"
        offset={8}
      />
    </>
  )
}

export default Folders
